import { useState, useContext } from "react";
import { rangeToArrayGigi } from "../utils/functions";
import { TypeGigi, TypePasien } from "../utils/types";
import EditGigiModal from "./EditGigiModal";
import Tooth from "./Tooth";
import ToothContainer from "./ToothContainer";
import { GigiContext } from "../contexts/GigiState";

const Odontogram: React.FC<{
  gigiList: TypeGigi[];
  currentPasien: TypePasien;
}> = ({ gigiList, currentPasien }) => {
  const kuadran1 = rangeToArrayGigi(18, 11, gigiList);
  const kuadran2 = rangeToArrayGigi(21, 28, gigiList);
  const kuadran1Mini = rangeToArrayGigi(55, 51, gigiList);
  const kuadran2Mini = rangeToArrayGigi(61, 65, gigiList);

  const kuadran4Mini = rangeToArrayGigi(85, 81, gigiList);
  const kuadran3Mini = rangeToArrayGigi(71, 75, gigiList);
  const kuadran4 = rangeToArrayGigi(48, 41, gigiList);
  const kuadran3 = rangeToArrayGigi(31, 38, gigiList);
  const [selectedGigi, setSelectedGigi] = useState<TypeGigi>(null);

  const { addGigi, editGigi } = useContext(GigiContext);
  const editData = async (data: TypeGigi) => {
    if (data.id !== undefined) {
      const res = await editGigi({
        ...data,
      });
      return res;
    } else {
      const res = await addGigi({
        ...data,
        no_rm: currentPasien.no_rm,
      });
      return res;
    }
  };
  return (
    <div className="w-full overflow-auto flex items-center justify-start">
      <div
        style={{ minWidth: "664px" }}
        className="w-full mx-10 overflow-visible grid grid-cols-2 gap-x-6 gap-y-10 my-10"
      >
        <div className="flex justify-end items-end">
          {kuadran1.map((item, index) => {
            return (
              <ToothContainer
                gigi={item}
                kuadran={1}
                setSelectedGigi={setSelectedGigi}
                key={index}
              >
                <Tooth gigi={item} />
              </ToothContainer>
            );
          })}
        </div>
        <div className="flex justify-start items-end">
          {kuadran2.map((item, index) => {
            return (
              <ToothContainer
                gigi={item}
                kuadran={2}
                setSelectedGigi={setSelectedGigi}
                key={index}
              >
                <Tooth gigi={item} />
              </ToothContainer>
            );
          })}
        </div>
        <div className="flex justify-end items-end">
          {kuadran1Mini.map((item, index) => {
            return (
              <ToothContainer
                gigi={item}
                kuadran={1}
                setSelectedGigi={setSelectedGigi}
                key={index}
              >
                <Tooth gigi={item} />
              </ToothContainer>
            );
          })}
        </div>
        <div className="flex justify-start items-end">
          {kuadran2Mini.map((item, index) => {
            return (
              <ToothContainer
                gigi={item}
                kuadran={2}
                setSelectedGigi={setSelectedGigi}
                key={index}
              >
                <Tooth gigi={item} />
              </ToothContainer>
            );
          })}
        </div>
        <div className="flex justify-end items-end">
          {kuadran4Mini.map((item, index) => {
            return (
              <ToothContainer
                gigi={item}
                kuadran={4}
                setSelectedGigi={setSelectedGigi}
                key={index}
              >
                <Tooth gigi={item} />
              </ToothContainer>
            );
          })}
        </div>
        <div className="flex justify-start items-end">
          {kuadran3Mini.map((item, index) => {
            return (
              <ToothContainer
                gigi={item}
                kuadran={3}
                setSelectedGigi={setSelectedGigi}
                key={index}
              >
                <Tooth gigi={item} />
              </ToothContainer>
            );
          })}
        </div>
        <div className="flex justify-end items-end">
          {kuadran4.map((item, index) => {
            return (
              <ToothContainer
                gigi={item}
                kuadran={4}
                setSelectedGigi={setSelectedGigi}
                key={index}
              >
                <Tooth gigi={item} />
              </ToothContainer>
            );
          })}
        </div>
        <div className="flex justify-start items-end">
          {kuadran3.map((item, index) => {
            return (
              <ToothContainer
                gigi={item}
                kuadran={3}
                setSelectedGigi={setSelectedGigi}
                key={index}
              >
                <Tooth gigi={item} />
              </ToothContainer>
            );
          })}
        </div>
      </div>
      <EditGigiModal
        gigi={selectedGigi}
        editData={editData}
        setGigi={setSelectedGigi}
      />
    </div>
  );
};

export default Odontogram;
