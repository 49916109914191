import { useContext, useState, useEffect } from "react";
import { useForm } from "antd/es/form/Form";
import { GlobalContext } from "../contexts/GlobalState";
import { Checkbox, Form, Input, Modal, Radio } from "antd";
import {
  FAILED_DISPLAY_MESSAGE,
  GIGI_JEMBATAN,
  GIGI_KONDISI_LAINNYA,
  GIGI_KONDISI_UMUM,
  GIGI_MIGRASI,
  GIGI_PERAWATAN_MAHKOTA,
} from "../utils/constants.tsx";
import { getKuadran } from "../utils/functions";
import { TypeDispatch, TypeGigi } from "../utils/types";
import Tooth from "./Tooth";
import ToothContainer from "./ToothContainer";
import EditSingleGigiModal from "./EditSingleGigiModal.tsx";

interface TypeEditGigiModal {
  gigi: TypeGigi;
  editData: (values: TypeGigi) => Promise<TypeDispatch<TypeGigi>>;
  setGigi: React.Dispatch<React.SetStateAction<TypeGigi>>;
}
const EditGigiModal: React.FC<TypeEditGigiModal> = ({
  gigi,
  editData,
  setGigi,
}) => {
  const [currentGigi, setCurrentGigi] = useState<TypeGigi>(null);
  const [currentKuadran, setCurrentKuadran] = useState<number>(null);
  useEffect(() => {
    if (gigi) {
      setCurrentGigi({ ...gigi });
      setCurrentKuadran(getKuadran(gigi));
      editForm.setFieldsValue({
        kondisi_umum: GIGI_KONDISI_UMUM.find((item) => gigi[item.value])?.value,
        perawatan_mahkota: GIGI_PERAWATAN_MAHKOTA.find(
          (item) => gigi[item.value]
        )?.value,
        jembatan: GIGI_JEMBATAN.find((item) => gigi.jembatan === item.value)
          ?.value,
        migrasi: GIGI_MIGRASI.find((item) => gigi.migrasi === item.value)
          ?.value,
        kondisi_lainnya: GIGI_KONDISI_LAINNYA.map((item) => item.value).filter(
          (item) => gigi[item]
        ),
        perawatan: gigi.perawatan,
        diagnosa: gigi.diagnosa,
        keterangan: gigi.keterangan,
      });
    } else {
      editForm.resetFields();
    }
  }, [gigi]);

  const { showLoadingMessage, showSuccessMessage, showErrorMessage } =
    useContext(GlobalContext);

  const [editForm] = useForm();
  const onSubmitForm = async (values) => {
    const messageKey = new Date().toISOString();
    showLoadingMessage(messageKey);

    const data = {
      ...currentGigi,
      perawatan: values.perawatan,
      diagnosa: values.diagnosa,
      keterangan: values.keterangan,
    };
    const res = await editData(data);
    if (res?.success) {
      showSuccessMessage(messageKey, "Data berhasil disimpan");
      setGigi(null);
      editForm.resetFields();
    } else {
      showErrorMessage(
        messageKey,
        res?.displayMessage || FAILED_DISPLAY_MESSAGE
      );
    }
  };
  const clearKondisi = (kondisiList: any[]) => {
    setCurrentGigi((prevGigi) => {
      const newGigi = { ...prevGigi };
      kondisiList.forEach((item) => {
        if (item.value !== "") newGigi[item.value] = null;
      });

      return newGigi;
    });
  };

  const [positionEdit, setPositionEdit] = useState<string>(null);

  return (
    <Modal
      title={"Edit Gigi"}
      open={!!gigi}
      okText="Simpan"
      cancelText="Batal"
      onOk={() => editForm.submit()}
      onCancel={() => setGigi(null)}
      className="max-w-none !w-11/12 md:!w-2/3 !p-4 flex flex-col gap-3"
      centered
      closable={false}
      maskClosable={false}
    >
      {currentGigi !== null ? (
        <>
          <div className="w-full flex justify-center items-center">
            <ToothContainer
              kuadran={currentKuadran}
              gigi={currentGigi}
              style={{
                width: "100%",
                marginTop:
                  (currentGigi.ano || currentGigi.une || currentGigi.pre) &&
                  currentKuadran <= 2
                    ? "50px"
                    : (currentGigi.migrasi ||
                        currentGigi.nonVital ||
                        currentGigi.perawatanSalAkar) &&
                      currentKuadran >= 3
                    ? "60px"
                    : "",
                marginBottom:
                  (currentGigi.ano || currentGigi.une || currentGigi.pre) &&
                  currentKuadran >= 3
                    ? "50px"
                    : (currentGigi.migrasi ||
                        currentGigi.nonVital ||
                        currentGigi.perawatanSalAkar) &&
                      currentKuadran <= 2
                    ? "60px"
                    : "",
              }}
            >
              <Tooth
                style={{
                  width: "30%",
                  minWidth: "50px",
                  maxWidth: "200px",
                }}
                gigi={currentGigi}
                detailPreview={true}
                openEditSingleGigiModal={setPositionEdit}
              />
            </ToothContainer>
          </div>

          <p className="w-full text-center text-md mt-3">
            Klik pada bagian gigi untuk menambah info
          </p>
          <Form
            form={editForm}
            className="w-full flex p-4 flex-col gap-3"
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            onFinish={onSubmitForm}
          >
            <Form.Item
              label={"Kondisi Umum"}
              name={"kondisi_umum"}
              className="m-0 flex flex-col"
            >
              <Radio.Group
                className="w-full flex flex-col items-start justify-center"
                options={GIGI_KONDISI_UMUM}
                onChange={(e) => {
                  const value = e.target.value;
                  clearKondisi(GIGI_KONDISI_UMUM);
                  if (value !== "") {
                    setCurrentGigi((prevGigi) => ({
                      ...prevGigi,
                      [value]: true,
                    }));
                  }
                }}
              />
            </Form.Item>

            <Form.Item
              label={"Perawatan Mahkota"}
              name={"perawatan_mahkota"}
              className="m-0 flex flex-col"
            >
              <Radio.Group
                className="w-full flex flex-col items-start justify-center"
                options={GIGI_PERAWATAN_MAHKOTA}
                onChange={(e) => {
                  const value = e.target.value;
                  clearKondisi(GIGI_PERAWATAN_MAHKOTA);
                  if (value !== "") {
                    setCurrentGigi((prevGigi) => ({
                      ...prevGigi,
                      [value]: true,
                    }));
                  }
                }}
              />
            </Form.Item>
            <Form.Item
              label={"Jembatan"}
              name={"jembatan"}
              className="m-0 flex flex-col"
            >
              <Radio.Group
                className="w-full flex flex-col items-start justify-center"
                options={GIGI_JEMBATAN.map((item) => ({
                  ...item,
                  label:
                    item.value === "" ? (
                      (item.label as string)
                    ) : (
                      <item.label
                        style={{
                          transform:
                            (item.value === "kiri" && currentKuadran >= 3) ||
                            (item.value === "kanan" && currentKuadran <= 2)
                              ? "scaleY(-1)"
                              : "",
                        }}
                      />
                    ),
                }))}
                onChange={(e) => {
                  const value = e.target.value;
                  setCurrentGigi((prevGigi) => ({
                    ...prevGigi,
                    jembatan: null,
                  }));
                  if (value !== "") {
                    setCurrentGigi((prevGigi) => ({
                      ...prevGigi,
                      jembatan: value,
                    }));
                  }
                }}
              />
            </Form.Item>
            <Form.Item
              label={"Migrasi/Version/Rotasi"}
              name={"migrasi"}
              className="m-0 flex flex-col"
            >
              <Radio.Group
                className="w-full flex flex-col items-start justify-center"
                options={GIGI_MIGRASI.map((item) => ({
                  ...item,
                  label:
                    item.value === "" ? (
                      (item.label as string)
                    ) : (
                      <item.label
                        style={{
                          transform:
                            (item.value === "kiri" && currentKuadran >= 3) ||
                            (item.value === "kanan" && currentKuadran >= 3)
                              ? "scaleY(-1)"
                              : "",
                        }}
                      />
                    ),
                }))}
                onChange={(e) => {
                  const value = e.target.value;
                  setCurrentGigi((prevGigi) => ({
                    ...prevGigi,
                    migrasi: null,
                  }));
                  if (value !== "") {
                    setCurrentGigi((prevGigi) => ({
                      ...prevGigi,
                      migrasi: value,
                    }));
                  }
                }}
              />
            </Form.Item>

            <Form.Item
              label={"Kondisi Lainnya"}
              name={"kondisi_lainnya"}
              className="m-0 flex flex-col"
            >
              <Checkbox.Group
                className="w-full flex flex-col items-start justify-center"
                onChange={(valList) => {
                  clearKondisi(GIGI_KONDISI_LAINNYA);
                  setCurrentGigi((prevGigi) => {
                    const newGigi = { ...prevGigi };
                    valList.forEach((item) => {
                      if (item !== "") {
                        newGigi[item as string] = true;
                      }
                    });
                    return newGigi;
                  });
                }}
              >
                {GIGI_KONDISI_LAINNYA.map((option, index) => (
                  <Checkbox key={index} value={option.value}>
                    {option.label}
                  </Checkbox>
                ))}
              </Checkbox.Group>
            </Form.Item>

            <div className="w-full flex flex-col gap-3">
              <Form.Item
                label={"Keterangan"}
                name={"keterangan"}
                className="m-0"
              >
                <Input.TextArea className="w-full" autoSize={{ minRows: 3 }} />
              </Form.Item>
            </div>
            <div className="w-full flex flex-col gap-3">
              <Form.Item label={"Diagnosa"} name={"diagnosa"} className="m-0">
                <Input.TextArea className="w-full" autoSize={{ minRows: 3 }} />
              </Form.Item>
            </div>
            <div className="w-full flex flex-col gap-3">
              <Form.Item label={"Perawatan"} name={"perawatan"} className="m-0">
                <Input.TextArea className="w-full" autoSize={{ minRows: 3 }} />
              </Form.Item>
            </div>
          </Form>
        </>
      ) : null}
      <EditSingleGigiModal
        currentGigi={currentGigi}
        setCurrentGigi={setCurrentGigi}
        position={positionEdit}
        setPosition={setPositionEdit}
      />
    </Modal>
  );
};

export default EditGigiModal;
