import { useContext, useEffect } from "react";
import { RekamMedisContext } from "../contexts/RekamMedisState";
import { TypeRekamMedis, TypeTableColumn } from "../utils/types";
import CustomTable from "../components/CustomTable";
import {
  DATE_FORMAT,
  REQUIRED_MESSAGE,
  SIZE_COLUMN_LG,
  SIZE_COLUMN_SM,
  SIZE_COLUMN_XL,
  SIZE_COLUMN_XS,
} from "../utils/constants.tsx";
import dayjs from "dayjs";
import { Button, DatePicker, Form } from "antd";
import {
  downloadExcel,
  formatIDRPrice,
  sortFunction,
} from "../utils/functions";
import { HiDownload } from "react-icons/hi";

const Laporan: React.FC = () => {
  const { allRekamMedisList, isLoadingGet, getRekamMedisByDate } =
    useContext(RekamMedisContext);

  // Perform get data
  useEffect(() => {
    getRekamMedisByDate({
      start_date: dayjs().startOf("day").toISOString(),
      end_date: dayjs().endOf("day").toISOString(),
    });
  }, []);

  const rekamMedisColumns: TypeTableColumn<TypeRekamMedis>[] = [
    {
      title: "No",
      dataIndex: "no",
      align: "center",
      width: SIZE_COLUMN_XS,
    },
    {
      title: "No RM",
      dataIndex: "no_rm",
      align: "center",
      width: SIZE_COLUMN_SM,
    },
    {
      title: "Tgl Periksa",
      dataIndex: "tgl_periksa",
      sorter: sortFunction("tgl_periksa"),
      width: SIZE_COLUMN_LG,
    },
    {
      title: "Nama Pasien",
      dataIndex: "nama_pasien",
      sorter: sortFunction("nama_pasien"),
      editable: true,
      width: SIZE_COLUMN_XL,
      editIndex: "id_pasien",
    },
    {
      title: "Nama Dokter",
      dataIndex: "nama_dokter",
      sorter: sortFunction("nama_dokter"),
      editable: true,
      width: SIZE_COLUMN_XL,
      editIndex: "id_dokter",
    },
    {
      title: "Perawatan",
      dataIndex: "perawatan",
      editable: true,
      className: "min-w-32",
      withQuantity: true,
    },
    {
      title: "Klinik",
      dataIndex: "ruang",
      editable: true,
      width: SIZE_COLUMN_XL,
      editIndex: "id_ruang",
    },
    {
      title: "Biaya",
      dataIndex: "biaya",
      width: SIZE_COLUMN_XL,
    },
  ];

  return (
    <div className="w-full flex flex-col p-6 gap-6 items-center">
      <div className="w-full rounded-lg bg-white overflow-hidden flex p-4 flex-col gap-4 shadow-md transition-all">
        <h3>Laporan Rekam Medis</h3>

        <div className="w-full flex gap-8 md:gap-3 flex-col items-end md:flex-row md:justify-between md:items-start">
          <Form
            onFinish={(values) => {
              getRekamMedisByDate({
                start_date: dayjs(values.startDate)
                  .startOf("day")
                  .toISOString(),
                end_date: dayjs(values.endDate).endOf("day").toISOString(),
              });
            }}
            className="w-full flex gap-3 items-start"
            labelCol={{ span: 24 }}
          >
            <Form.Item
              initialValue={dayjs().startOf("day")}
              name="startDate"
              label="Tanggal awal"
              className="m-0 w-44 no-asterisk"
              rules={[
                {
                  required: true,
                  message: REQUIRED_MESSAGE,
                },
              ]}
            >
              <DatePicker format={DATE_FORMAT} />
            </Form.Item>
            <Form.Item
              initialValue={dayjs().endOf("day")}
              name="endDate"
              label="Tanggal akhir"
              className="m-0 w-44 no-asterisk"
              rules={[
                {
                  required: true,
                  message: REQUIRED_MESSAGE,
                },
              ]}
            >
              <DatePicker format={DATE_FORMAT} />
            </Form.Item>
            <Button type="primary" htmlType="submit" className="mt-10">
              Filter
            </Button>
          </Form>

          <Button
            type="primary"
            className="md:mt-10 flex items-center justify-center gap-2"
            disabled={isLoadingGet || allRekamMedisList.length === 0}
            onClick={() =>
              downloadExcel(
                rekamMedisColumns,
                allRekamMedisList.map((item) => {
                  let biaya = 0;
                  item.perawatan?.forEach((item2) => {
                    biaya += item2.harga * item2.jumlah;
                  });
                  return {
                    ...item,
                    biaya: biaya,
                  };
                }),
                `Rekam Medis ${process.env.REACT_APP_APP_NAME} ` +
                  dayjs().format(DATE_FORMAT)
              )
            }
          >
            <HiDownload size={18} />
            Unduh Laporan
          </Button>
        </div>

        <CustomTable<TypeRekamMedis>
          pagination={null}
          dataList={allRekamMedisList.map((item) => {
            let biaya = 0;
            item.perawatan?.forEach((item2) => {
              biaya += item2.harga * item2.jumlah;
            });
            return {
              ...item,
              biaya: formatIDRPrice(biaya),
            };
          })}
          columns={rekamMedisColumns}
          isLoading={isLoadingGet}
          setFilter={null}
          filter={null}
          editingItem={null}
          setEditingItem={null}
          editData={null}
        />
      </div>
    </div>
  );
};

export default Laporan;
