import { useContext } from "react";
import { useForm } from "antd/es/form/Form";
import { GlobalContext } from "../contexts/GlobalState";
import { Form, Input, Modal } from "antd";
import {
  FAILED_DISPLAY_MESSAGE,
  REQUIRED_MESSAGE,
} from "../utils/constants.tsx";
import { TypeUser } from "../utils/types";

interface TypeResetPasswordModal {
  item: TypeUser;
  changePassword: (values: any) => Promise<any>;
  setItem: React.Dispatch<React.SetStateAction<TypeUser>>;
}
const ResetPasswordModal: React.FC<TypeResetPasswordModal> = ({
  item,
  changePassword,
  setItem,
}) => {
  const { showLoadingMessage, showSuccessMessage, showErrorMessage } =
    useContext(GlobalContext);

  const [passwordForm] = useForm();
  const onSubmitForm = async (values) => {
    const messageKey = new Date().toISOString();
    showLoadingMessage(messageKey);
    const res = await changePassword({
      id: item.id,
      password: values.password,
    });
    if (res?.success) {
      showSuccessMessage(messageKey, "Password berhasil diubah");
      setItem(null);
      passwordForm.resetFields();
    } else {
      showErrorMessage(
        messageKey,
        res?.displayMessage || FAILED_DISPLAY_MESSAGE
      );
    }
  };

  return (
    <Modal
      title="Ubah Password"
      open={!!item}
      okText="Ubah Password"
      onOk={() => passwordForm.submit()}
      onCancel={() => setItem(null)}
      className="max-w-none !w-5/6 md:!w-2/3 !p-4 flex flex-col gap-3"
      centered
    >
      <Form
        form={passwordForm}
        className="w-full flex p-4 flex-col gap-3"
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        onFinish={onSubmitForm}
      >
        <Form.Item
          rules={[
            {
              required: true,
              message: REQUIRED_MESSAGE,
            },
          ]}
          label="Password Baru"
          name="password"
          className="m-0"
        >
          <Input.Password className="w-full" />
        </Form.Item>
        <Form.Item
          label="Konfirmasi Password"
          name="confirmPassword"
          dependencies={["password"]}
          className="m-0"
          rules={[
            {
              required: true,
              message: REQUIRED_MESSAGE,
            },
            {
              validator: (_, value) => {
                const password = passwordForm.getFieldValue("password");
                if (password === value) {
                  return Promise.resolve();
                } else {
                  return Promise.reject("Password tidak cocok");
                }
              },
            },
          ]}
        >
          <Input.Password />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ResetPasswordModal;
