import React, { PropsWithChildren, useContext, useEffect } from "react";
import { Dropdown, Layout, Menu, Space, theme } from "antd";
import {
  DownOutlined,
  FileTextOutlined,
  LogoutOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
} from "@ant-design/icons";
import { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { GlobalContext } from "../contexts/GlobalState";
import { FaBriefcaseMedical, FaUserDoctor } from "react-icons/fa6";
import { RiAdminFill } from "react-icons/ri";
import { FaUserAlt } from "react-icons/fa";
import { BiSolidHome } from "react-icons/bi";
import { getLevel1Path } from "../utils/functions";

const { Header, Sider, Content } = Layout;

const DashboardLayout: React.FC<PropsWithChildren> = ({ children }) => {
  const {
    token: { colorBgContainer },
  } = theme.useToken();
  const { user, logout } = useContext(GlobalContext);
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [collapsed, setCollapsed] = useState(true);

  const menuItems = [
    {
      key: "/",
      icon: <FileTextOutlined />,
      label: <Link to="/">Dashboard</Link>,
      title: "Dashboard",
    },
    {
      key: "/dokter",
      icon: <FaUserDoctor />,
      label: <Link to="/dokter">Dokter</Link>,
      title: "Dokter",
    },
    {
      key: "/pasien",
      icon: <FaUserAlt />,
      label: <Link to="/pasien">Pasien</Link>,
      title: "Pasien",
    },
    {
      key: "/ruang",
      icon: <BiSolidHome />,
      label: <Link to="/ruang">Klinik</Link>,
      title: "Klinik",
    },
    {
      key: "/perawatan",
      icon: <FaBriefcaseMedical />,
      label: <Link to="/perawatan">Perawatan</Link>,
      title: "Perawatan",
    },
    {
      key: "/admin",
      icon: <RiAdminFill />,
      label: <Link to="/admin">Admin</Link>,
      title: "Admin",
      wajibSuperAdmin: true,
    },
    {
      key: "/laporan",
      icon: <RiAdminFill />,
      label: <Link to="/laporan">Laporan</Link>,
      title: "Laporan",
      wajibSuperAdmin: true,
    },
    // {
    //   key: "/rekam-medis",
    //   icon: <FileDoneOutlined />,
    //   label: <Link to="/rekam-medis">Rekam Medis</Link>,
    //   title: "Rekam Medis",
    // },
    // ...(pageProps?.user?.tipe === "P"
    //   ? []
    //   : [
    //       {
    //         key: "/manajemen-admin",
    //         icon: <UsergroupAddOutlined />,
    //         label: <Link to="/manajemen-admin">Manajemen Admin</Link>,
    //         title: "Manajemen Admin",
    //       },
    //     ]),
  ];

  const menuItemsBiasa = menuItems.filter((item) => !item.wajibSuperAdmin);

  const dropdownItems = [
    {
      key: "0",
      icon: <LogoutOutlined />,
      label: "Logout",
      onClick: () => logout(),
    },
  ];

  useEffect(() => {
    if (user?.role !== "SUPERADMIN") {
      const level1Path = getLevel1Path(pathname);
      if (!menuItemsBiasa.map((item) => item.key).includes(level1Path)) {
        navigate("/");
      }
    }
  }, [pathname]);

  return (
    // <Layout className="h-100/70-screen md:h-screen">
    <Layout className="h-min min-h-100/70-screen md:min-h-screen relative">
      <Sider
        width={200}
        trigger={null}
        collapsible
        collapsed={collapsed}
        collapsedWidth={70}
        className={`px-3 z-50 h-full md:h-auto !absolute md:!relative inherited-children !box-content ${
          collapsed ? "-left-24" : ""
        } md:left-0 transition-all`}
      >
        <div
          style={{ width: "inherit", maxWidth: "inherit" }}
          className="box-content min-h-100/70-screen md:min-h-screen fixed flex flex-col gap-2"
        >
          <div
            style={{
              fontSize: "1.5rem",
              fontWeight: "600",
              color: "white",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
              marginTop: "1rem",
              marginBottom: "1rem",
            }}
          >
            {collapsed ? (
              <div
                onClick={() => setCollapsed(false)}
                className="max-w-20 w-full flex items-center justify-center cursor-pointer"
              >
                <img
                  src={`${process.env.PUBLIC_URL}/assets/images/${process.env.REACT_APP_APP_NAME}.png`}
                  alt="logo"
                  className="w-full h-full object-contain"
                />
              </div>
            ) : (
              <div className="flex flex-col items-center justify-start w-full gap-1">
                <div
                  onClick={() => setCollapsed(true)}
                  className="w-1/3 flex items-center justify-center aspect-square cursor-pointer"
                >
                  <img
                    src={`${process.env.PUBLIC_URL}/assets/images/${process.env.REACT_APP_APP_NAME}.png`}
                    alt="logo"
                    className="w-full h-full object-contain"
                  />
                </div>
                <h4 className="m-0 text-center">
                  {process.env.REACT_APP_APP_NAME}
                </h4>
              </div>
            )}
          </div>
          <Menu
            theme="dark"
            mode="inline"
            selectedKeys={[
              menuItems.find((item) => {
                const level1Path = getLevel1Path(pathname);
                return level1Path === item.key;
              })?.key || "0",
            ]}
            items={(user?.role === "SUPERADMIN"
              ? menuItems
              : menuItemsBiasa
            ).map((item) => {
              const newItem = {
                ...item,
                onClick: () => {
                  setCollapsed(true);
                },
              };
              delete newItem.wajibSuperAdmin;
              return newItem;
            })}
          />
        </div>
      </Sider>
      <div
        className="bg-gray-200 opacity-50 w-full h-full absolute z-40 md:!hidden"
        onClick={() => setCollapsed(true)}
        style={{ display: collapsed ? "none" : "block" }}
      ></div>
      <Layout className="site-layout h-full">
        <Header
          style={{
            background: colorBgContainer,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            // gap: "1rem",
          }}
          className="px-6"
        >
          <div
            className="gap-2"
            style={{ display: "flex", alignItems: "center" }}
          >
            {React.createElement(
              collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
              {
                className: "trigger",
                onClick: () => setCollapsed(!collapsed),
                style: {
                  padding: "0",
                  fontSize: "18px",
                  lineHeight: "64px",
                  cursor: "pointer",
                  transition: "color 0.3s",
                },
              }
            )}
            <p>
              {
                menuItems.find((item) => {
                  const level1Path = getLevel1Path(pathname);
                  return level1Path === item.key;
                })?.title
              }
            </p>
          </div>

          <Dropdown
            menu={{
              items: dropdownItems,
            }}
            trigger={["click"]}
            overlayClassName="w-32"
          >
            <div
              onClick={(e) => e.preventDefault()}
              style={{ marginRight: "1rem" }}
              className="cursor-pointer"
            >
              <Space>
                {user?.nama}
                <DownOutlined />
              </Space>
            </div>
          </Dropdown>
        </Header>
        <Content>{children}</Content>
      </Layout>
    </Layout>
  );
};

export default DashboardLayout;
