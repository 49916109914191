import { useEffect, useState } from "react";
import { useForm } from "antd/es/form/Form";
import { Form, Modal, Radio } from "antd";
import { GIGI_KONDISI_SPESIFIK } from "../utils/constants.tsx";
import { TypeGigi } from "../utils/types";

interface TypeEditSingleGigiModal {
  currentGigi: TypeGigi;
  setCurrentGigi: React.Dispatch<React.SetStateAction<TypeGigi>>;
  position: string;
  setPosition: React.Dispatch<React.SetStateAction<string>>;
}
const EditSingleGigiModal: React.FC<TypeEditSingleGigiModal> = ({
  currentGigi,
  setCurrentGigi,
  position,
  setPosition,
}) => {
  const [oldCurrentGigi, setOldCurrentGigi] = useState<TypeGigi>(null);
  useEffect(() => {
    if (currentGigi) {
      setOldCurrentGigi(currentGigi);
      editForm.setFieldsValue({
        kondisi_spesifik: GIGI_KONDISI_SPESIFIK.find(
          (item) => currentGigi[position]?.[item.value] === true
        )?.value,
      });
    } else {
      editForm.resetFields();
    }
  }, [position]);

  const [editForm] = useForm();

  return (
    <Modal
      title={"Edit Kondisi Spesifik Gigi"}
      open={!!position}
      okText="Simpan"
      cancelText="Batal"
      onOk={() => setPosition(null)}
      onCancel={() => {
        setCurrentGigi(oldCurrentGigi);
        setPosition(null);
      }}
      className="max-w-none !w-11/12 md:!w-1/3 !p-4 flex flex-col gap-3"
      centered
    >
      {currentGigi !== null ? (
        <>
          <Form
            form={editForm}
            className="w-full flex p-4 flex-col gap-3"
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
          >
            <Form.Item
              label={"Kondisi Spesifik"}
              name={"kondisi_spesifik"}
              className="m-0 flex flex-col"
            >
              <Radio.Group
                className="w-full flex flex-col items-start justify-center"
                options={GIGI_KONDISI_SPESIFIK}
                onChange={(e) => {
                  const value = e.target.value;
                  setCurrentGigi((prevGigi) => ({
                    ...prevGigi,
                    [position]: {
                      caries: false,
                      tambalanLogam: false,
                      tambalanNonLogam: false,
                    },
                  }));
                  if (value !== "") {
                    setCurrentGigi((prevGigi) => ({
                      ...prevGigi,
                      [position]: {
                        [value]: true,
                      },
                    }));
                  }
                }}
              />
            </Form.Item>
          </Form>
        </>
      ) : null}
    </Modal>
  );
};

export default EditSingleGigiModal;
