import { PropsWithChildren, createContext, useState } from "react";
import { TypeDokter, TypeDokterContext } from "../utils/types";
import axios from "axios";
import { FAILED_DATA } from "../utils/constants.tsx";
import { host } from "./config";

axios.defaults.withCredentials = true;
const initialState: TypeDokterContext = {
  dokterList: [],
  allDokterList: [],
  pagination: {
    current: 1,
    pageSize: 10,
    total: 0,
  },
  isLoadingGet: false,
};
export const DokterContext = createContext<TypeDokterContext>(initialState);
const DokterState: React.FC<PropsWithChildren> = ({ children }) => {
  const [state, setState] = useState<TypeDokterContext>(initialState);
  const dispatch = (payload: Partial<TypeDokterContext>) => {
    setState((prevState) => ({
      ...prevState,
      ...payload,
    }));
  };

  const getDokter = async (data: {
    page?: number;
    query?: string;
    sort_field?: string;
    is_asc?: boolean;
  }) => {
    dispatch({ isLoadingGet: true });
    try {
      const res = await axios.get(host + "/dokter/get_dokter.php", {
        params: data,
      });
      if (res.data.success) {
        dispatch({
          dokterList: res.data.data || [],
          pagination: res.data.pagination,
        });
      }
      return res.data;
    } catch (err) {
      return FAILED_DATA;
    } finally {
      dispatch({ isLoadingGet: false });
    }
  };

  const getAllDokter = async () => {
    dispatch({ isLoadingGet: true });
    try {
      const res = await axios.get(host + "/dokter/get_all_dokter.php");
      if (res.data.success) {
        dispatch({
          allDokterList: res.data.data || [],
        });
      }
      return res.data;
    } catch (err) {
      return FAILED_DATA;
    } finally {
      dispatch({ isLoadingGet: false });
    }
  };

  const addDokter = async (data: TypeDokter) => {
    try {
      const res = await axios.post(host + "/dokter/add_dokter.php", data);
      return res.data;
    } catch (err) {
      return FAILED_DATA;
    }
  };

  const editDokter = async (data: Partial<TypeDokter>) => {
    try {
      const res = await axios.post(host + "/dokter/edit_dokter.php", data);

      const prevIndex = state.dokterList.findIndex(
        (item) => item.id === data.id
      );
      if (prevIndex !== -1) {
        const newDokterList = [...state.dokterList];
        newDokterList[prevIndex] = {
          ...newDokterList[prevIndex],
          ...data,
        };
        dispatch({ dokterList: newDokterList });
      }

      return res.data;
    } catch (err) {
      return FAILED_DATA;
    }
  };

  const deleteDokter = async (id: number) => {
    try {
      const res = await axios.post(host + "/dokter/delete_dokter.php", { id });

      const prevIndex = state.dokterList.findIndex((item) => item.id === id);
      if (prevIndex !== -1) {
        const newDokterList = [...state.dokterList];
        newDokterList.splice(prevIndex, 1);
        dispatch({ dokterList: newDokterList });
      }

      return res.data;
    } catch (err) {
      return FAILED_DATA;
    }
  };

  return (
    <DokterContext.Provider
      value={{
        ...state,
        getDokter,
        getAllDokter,
        addDokter,
        editDokter,
        deleteDokter,
      }}
    >
      {children}
    </DokterContext.Provider>
  );
};

export default DokterState;
