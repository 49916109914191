import { useForm } from "antd/es/form/Form";
import { Form, Modal, Select } from "antd";
import { REQUIRED_MESSAGE } from "../utils/constants.tsx";
import { TypeRuang } from "../utils/types";

interface TypeSelectRuangModal {
  modalOpen: boolean;
  setModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  allRuangList: TypeRuang[];
  setCurrentRuang: React.Dispatch<React.SetStateAction<string>>;
}

const SelectRuangModal: React.FC<TypeSelectRuangModal> = ({
  modalOpen,
  setModalOpen,
  allRuangList,
  setCurrentRuang,
}) => {
  const [ruangForm] = useForm();
  const onSubmitForm = async (values) => {
    setCurrentRuang(values?.id_ruang);
  };

  return (
    <Modal
      title="Pilih klinik"
      open={modalOpen}
      okText="OK"
      onOk={() => ruangForm.submit()}
      onCancel={() => setModalOpen(false)}
      className="max-w-none !w-5/6 md:!w-2/3 !p-4 flex flex-col gap-3"
      cancelButtonProps={{ style: { display: "none" } }}
      centered
    >
      <Form
        form={ruangForm}
        className="w-full flex p-4 flex-col gap-3"
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        onFinish={onSubmitForm}
      >
        <Form.Item
          rules={[
            {
              required: true,
              message: REQUIRED_MESSAGE,
            },
          ]}
          label="Klinik"
          name="id_ruang"
          className="m-0"
        >
          <Select
            showSearch
            placeholder="Pilih klinik"
            className="w-full"
            optionFilterProp="children"
            filterOption={(
              input: string,
              option: { label: string; value: string }
            ) =>
              (option?.label ?? "")
                .toLowerCase()
                .includes((input ?? "").toLowerCase())
            }
            options={allRuangList.map((item) => ({
              label: item.nama,
              value: String(item.id),
            }))}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default SelectRuangModal;
