import { PropsWithChildren, createContext, useState } from "react";
import { TypeGigi, TypeGigiContext } from "../utils/types";
import axios from "axios";
import { FAILED_DATA } from "../utils/constants.tsx";
import { host } from "./config";
import { apiDataToGigi, gigiToApiData } from "../utils/functions.tsx";

axios.defaults.withCredentials = true;
const initialState: TypeGigiContext = {
  allGigiList: [],
  isLoadingGet: false,
};
export const GigiContext = createContext<TypeGigiContext>(initialState);
const GigiState: React.FC<PropsWithChildren> = ({ children }) => {
  const [state, setState] = useState<TypeGigiContext>(initialState);
  const dispatch = (payload: Partial<TypeGigiContext>) => {
    setState((prevState) => ({
      ...prevState,
      ...payload,
    }));
  };

  const getGigiByNoRM = async (no_rm: string) => {
    dispatch({ isLoadingGet: true });
    try {
      const res = await axios.get(host + "/gigi/get_gigi_by_no_rm.php", {
        params: { no_rm },
      });
      if (res.data.success) {
        const formattedData: TypeGigi[] = res.data.data?.map((item) =>
          apiDataToGigi(item)
        );
        dispatch({
          allGigiList: formattedData || [],
        });
      }
      return res.data;
    } catch (err) {
      return FAILED_DATA;
    } finally {
      dispatch({ isLoadingGet: false });
    }
  };

  const addGigi = async (data: TypeGigi) => {
    try {
      const formattedData = gigiToApiData(data);
      const res = await axios.post(host + "/gigi/add_gigi.php", formattedData);
      if (res.data.success) {
        res.data.data = apiDataToGigi(res.data.data);
        dispatch({
          allGigiList: [...state.allGigiList, res.data.data],
        });
      }
      return res.data;
    } catch (err) {
      return FAILED_DATA;
    }
  };

  const editGigi = async (data: TypeGigi) => {
    try {
      const formattedData = gigiToApiData(data);
      const res = await axios.post(host + "/gigi/edit_gigi.php", formattedData);

      const prevIndex = state.allGigiList.findIndex(
        (item) => item.id === data.id
      );
      if (prevIndex !== -1) {
        const newGigiList = [...state.allGigiList];
        newGigiList[prevIndex] = {
          ...newGigiList[prevIndex],
          ...data,
        };
        dispatch({ allGigiList: newGigiList });
      }

      res.data.data = apiDataToGigi(res.data.data);
      return res.data;
    } catch (err) {
      return FAILED_DATA;
    }
  };

  return (
    <GigiContext.Provider
      value={{
        ...state,
        addGigi,
        editGigi,
        getGigiByNoRM,
      }}
    >
      {children}
    </GigiContext.Provider>
  );
};

export default GigiState;
