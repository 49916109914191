import { useContext } from "react";
import {
  Button,
  DatePicker,
  Form,
  Input,
  Pagination,
  Radio,
  Table,
  TablePaginationConfig,
  Tooltip,
} from "antd";
import {
  TypeFilterTable,
  TypePagination,
  TypeTableColumn,
} from "../utils/types";
import { FilterValue, SorterResult } from "antd/es/table/interface";
import { FormInstance, useForm } from "antd/es/form/Form";
import { BiCheck, BiX } from "react-icons/bi";
import { GlobalContext } from "../contexts/GlobalState";
import {
  DATE_FORMAT,
  FAILED_DISPLAY_MESSAGE,
  REQUIRED_MESSAGE,
  SORTER_TOOLTIP,
  dateColumns,
  mappedColumns,
  noSpaceColumns,
  numberColumns,
  priceColumns,
  radioColumns,
  textareaColumns,
} from "../utils/constants.tsx";
import dayjs from "dayjs";
import { formatIDRPrice, generateArray } from "../utils/functions";

interface CustomTableProps<A> {
  pagination: TypePagination;
  dataList: A[];
  columns: TypeTableColumn<A>[];
  isLoading: boolean;
  editingItem: A & { id?: number };
  filter: TypeFilterTable<A>;
  setFilter: React.Dispatch<React.SetStateAction<TypeFilterTable<A>>>;
  setEditingItem: React.Dispatch<React.SetStateAction<A>>;
  editData: (data: any) => Promise<any>;
}

export const EditorComponent: React.FC<{
  text: any;
  column: any;
  dataForm: FormInstance<any>;
  quantityState?: {
    dataIndex: string;
    quantity: number;
  }[];
  handleAddField?: (dataIndex: string, count?: number) => void;
  handleRemoveField?: (
    dataIndex: string,
    itemIndex: number,
    suffixList: string[]
  ) => void;
}> = ({
  text,
  column,
  dataForm,
  quantityState,
  handleAddField,
  handleRemoveField,
}) => {
  const { dataIndex } = column;

  if (column.withQuantity) {
    let quantity = quantityState.find(
      (item2) => item2.dataIndex === column.dataIndex
    ).quantity;

    const quantityList = generateArray(quantity);
    return (
      <div className="w-full flex flex-col items-start gap-2">
        <div className="flex flex-col gap-2 w-full">
          <column.CustomComponentEditorRender
            item={column}
            quantityList={quantityList}
            handleRemoveField={handleRemoveField}
            addForm={dataForm}
            initialValue={text}
          />
        </div>
        <Button
          type="primary"
          onClick={() => handleAddField(column.dataIndex as string)}
        >
          Tambah
        </Button>
      </div>
    );
  } else if (numberColumns.includes(dataIndex)) {
    dataForm.setFieldValue(dataIndex, text);
    return (
      <Form.Item
        name={dataIndex as string}
        rules={[{ required: !column.optional, message: REQUIRED_MESSAGE }]}
        className="w-full flex items-center justify-start m-0"
      >
        <Input
          onChange={(e) => {
            const value = e.target.value;
            dataForm.setFieldValue(dataIndex, value.replace(/[^0-9]/g, ""));
          }}
        />
      </Form.Item>
    );
  } else if (dateColumns.includes(dataIndex)) {
    dataForm.setFieldValue(dataIndex, dayjs(text));
    return (
      <Form.Item
        name={dataIndex as string}
        rules={[{ required: !column.optional, message: REQUIRED_MESSAGE }]}
        className="w-full flex items-center justify-start m-0"
      >
        <DatePicker placeholder="DD-MM-YYYY" format={DATE_FORMAT} />
      </Form.Item>
    );
  } else if (radioColumns.includes(dataIndex)) {
    dataForm.setFieldValue(dataIndex, text);
    return (
      <Form.Item
        name={dataIndex as string}
        rules={[{ required: !column.optional, message: REQUIRED_MESSAGE }]}
        className="w-full flex items-start justify-start m-0"
      >
        <Radio.Group
          className="w-full flex flex-col items-start justify-center"
          options={column.columnOptions}
        />
      </Form.Item>
    );
  } else if (textareaColumns.includes(dataIndex)) {
    dataForm.setFieldValue(dataIndex, text);
    return (
      <Form.Item
        name={dataIndex as string}
        rules={[{ required: !column.optional, message: REQUIRED_MESSAGE }]}
        className="w-full flex items-center justify-start m-0 inherit-w-full"
      >
        <Input.TextArea
          maxLength={1000}
          className="w-full"
          autoSize={{ minRows: 3 }}
        />
      </Form.Item>
    );
  } else if (noSpaceColumns.includes(dataIndex)) {
    dataForm.setFieldValue(dataIndex, text);
    return (
      <Form.Item
        name={dataIndex as string}
        rules={[{ required: !column.optional, message: REQUIRED_MESSAGE }]}
        className="w-full flex items-center justify-start m-0"
      >
        <Input
          onChange={(e) => {
            const value = (e.target?.value || "").replaceAll(" ", "");
            dataForm.setFieldValue(dataIndex, value);
          }}
        />
      </Form.Item>
    );
  } else {
    dataForm.setFieldValue(dataIndex, text);
    return (
      <Form.Item
        name={dataIndex as string}
        rules={[{ required: !column.optional, message: REQUIRED_MESSAGE }]}
        className="w-full flex items-center justify-start m-0"
      >
        <Input />
      </Form.Item>
    );
  }
};

export const ContentComponent: React.FC<{
  text: string;
  record: any;
  column: any;
}> = ({ text, record, column }) => {
  const { dataIndex } = column;
  let wrappedElement = <p>{text}</p>;
  if (dateColumns.includes(dataIndex)) {
    const formattedDate = dayjs(text).format(DATE_FORMAT);
    wrappedElement = <p>{formattedDate}</p>;
  } else if (radioColumns.includes(dataIndex)) {
    const displayText =
      column.columnOptions?.find?.((item) => item.value === text)?.label ||
      "Tidak diketahui";
    wrappedElement = <p>{displayText}</p>;
  } else if (textareaColumns.includes(dataIndex)) {
    wrappedElement = <p className="whitespace-pre-wrap">{text}</p>;
  } else if (priceColumns.includes(dataIndex)) {
    const formattedPrice = formatIDRPrice(parseInt(text));
    wrappedElement = <p>{formattedPrice}</p>;
  } else if (mappedColumns.map((item) => item.name).includes(dataIndex)) {
    const columnItem = mappedColumns.find((item) => item.name === dataIndex);
    const attributeName = columnItem.attributeName;
    const attributeJumlah = columnItem.attributeJumlah;
    const dataList: any[] = record[dataIndex] || [];
    return (
      <ul className="w-full pl-2">
        {dataList.length ? (
          column.CustomComponentContentRender ? (
            <column.CustomComponentContentRender record={record} />
          ) : (
            dataList.map((item, index) => {
              const jumlah = item[attributeJumlah];
              if (jumlah === undefined) {
                return (
                  <li key={index}>
                    <p>{item[attributeName]}</p>
                  </li>
                );
              } else {
                return (
                  <li key={index}>
                    <p>
                      {item[attributeName]} ({jumlah})
                    </p>
                  </li>
                );
              }
            })
          )
        ) : (
          <p className="text-center"></p>
        )}
      </ul>
    );
  }

  if (column.CustomComponentContentRender) {
    return (
      <column.CustomComponentContentRender record={record}>
        {wrappedElement}
      </column.CustomComponentContentRender>
    );
  } else {
    return wrappedElement;
  }
};

const CustomTable = <A,>({
  pagination,
  dataList,
  columns,
  isLoading,
  editingItem,
  filter,
  setFilter,
  setEditingItem,
  editData,
}: CustomTableProps<A>) => {
  const { showLoadingMessage, showSuccessMessage, showErrorMessage } =
    useContext(GlobalContext);
  const handleTableChange = (
    newPagination: TablePaginationConfig,
    filters: Record<string, FilterValue>,
    sorter: SorterResult<A> | SorterResult<A>[]
  ) => {
    if (Array.isArray(sorter)) {
      const singleSorter = sorter[0];
      setFilter((prevFilter) => ({
        page: newPagination.current,
        sortField: (singleSorter.column?.dataIndex ||
          prevFilter.sortField) as keyof A,
        isAsc: !filter.isAsc,
      }));
    } else {
      setFilter((prevFilter) => ({
        page: newPagination.current,
        sortField: (sorter.column?.dataIndex ||
          prevFilter.sortField) as keyof A,
        isAsc: !filter.isAsc,
      }));
    }
  };

  const isEditing = <A extends { id?: number }>(item: A) => {
    return item.id === editingItem?.id;
  };

  const generateRenderFunction = <A,>(column: any) => {
    return (text: string, record: A) => {
      return isEditing(record) ? (
        <EditorComponent text={text} column={column} dataForm={dataForm} />
      ) : (
        <ContentComponent text={text} record={record} column={column} />
      );
    };
  };

  const [dataForm] = useForm();

  const generateEditingRender = (originalRender) => {
    return (text: string, item: any) => {
      if (isEditing(item)) {
        return (
          <div className="flex gap-3 items-center justify-center">
            <Tooltip title="Simpan">
              <Button
                type="primary"
                className="flex items-center justify-center p-1"
                onClick={() => dataForm.submit()}
              >
                <BiCheck size={20} />
              </Button>
            </Tooltip>
            <Tooltip title="Batalkan">
              <Button
                type="primary"
                className="flex items-center justify-center p-1"
                onClick={() => setEditingItem(null)}
                danger
              >
                <BiX size={20} />
              </Button>
            </Tooltip>
          </div>
        );
      } else {
        return originalRender(text, item);
      }
    };
  };

  const onEditData = async (values) => {
    const messageKey = new Date().toISOString();
    showLoadingMessage(messageKey);
    const res = await editData(values);
    if (res?.success) {
      setEditingItem(null);
      showSuccessMessage(messageKey, "Data berhasil diubah");
      dataForm.resetFields();
    } else {
      showErrorMessage(
        messageKey,
        res?.displayMessage || FAILED_DISPLAY_MESSAGE
      );
    }
  };

  const MainTable = () => (
    <>
      <Table
        bordered
        className="w-full rounded-lg"
        pagination={
          pagination ? false : { pageSize: 10, total: dataList.length }
        }
        dataSource={dataList.map((item, index) => {
          if (pagination)
            return {
              ...item,
              no: pagination.pageSize * (pagination.current - 1) + index + 1,
            };
          else
            return {
              ...item,
              no: index + 1,
            };
        })}
        rowKey="no"
        columns={columns.map((item) => {
          item = {
            ...item,
            showSorterTooltip: { title: SORTER_TOOLTIP },
            sortDirections: ["descend", "ascend"],
          };
          if (item.editable) {
            if (item.dataIndex === "alamat" && editingItem) {
              return {
                ...item,
                width: 180,
                render: generateRenderFunction<A>(item),
              };
            }

            return {
              ...item,
              render: generateRenderFunction<A>(item),
            };
          } else if (item.title === "Aksi") {
            return {
              ...item,
              render: generateEditingRender(item.render),
            };
          } else if (
            !item.editable &&
            editingItem &&
            item.dataIndex !== "nama"
          ) {
            return { ...item, width: 0, ellipsis: true };
          }

          return {
            ...item,
            render: (text: string, record: A) => {
              return (
                <ContentComponent column={item} text={text} record={record} />
              );
            },
          };
        })}
        onChange={pagination ? handleTableChange : null}
      />
      {isLoading ? (
        <div
          role="status"
          className="absolute w-full h-full top-0 left-0 flex items-center justify-center bg-gray-200 opacity-50"
        >
          <svg
            aria-hidden="true"
            className="w-16 h-16 mr-2 text-white animate-spin fill-blue-600"
            viewBox="0 0 100 101"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
              fill="currentColor"
            />
            <path
              d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
              fill="currentFill"
            />
          </svg>
        </div>
      ) : null}
    </>
  );
  return (
    <div className="w-full relative flex flex-col gap-3 items-center justify-center ">
      <div className="w-full relative flex items-center justify-center overflow-auto">
        {editData ? (
          <Form form={dataForm} className="w-full" onFinish={onEditData}>
            <MainTable />
          </Form>
        ) : (
          <MainTable />
        )}
      </div>
      {pagination ? (
        <div className="flex justify-end w-full">
          <Pagination
            current={pagination.current}
            onChange={(currentPage) => {
              setFilter((prevFilter) => ({ ...prevFilter, page: currentPage }));
            }}
            total={pagination.total}
          />
        </div>
      ) : null}
    </div>
  );
};

export default CustomTable;
