import { PropsWithChildren } from "react";
import GlobalState from "../contexts/GlobalState";
import DokterState from "../contexts/DokterState";
import PasienState from "../contexts/PasienState";
import { ConfigProvider } from "antd";
import { EMPTY_DATA } from "../utils/constants.tsx";
import RuangState from "../contexts/RuangState";
import PerawatanState from "../contexts/PerawatanState";
import RekamMedisState from "../contexts/RekamMedisState";
import AntreanState from "../contexts/AntreanState";
import GigiState from "../contexts/GigiState.tsx";

const Providers: React.FC<PropsWithChildren> = ({ children }) => {
  return (
    <GlobalState>
      <DokterState>
        <PasienState>
          <RuangState>
            <PerawatanState>
              <RekamMedisState>
                <AntreanState>
                  <GigiState>
                    <ConfigProvider
                      renderEmpty={() => <p className="p-2">{EMPTY_DATA}</p>}
                    >
                      {children}
                    </ConfigProvider>
                  </GigiState>
                </AntreanState>
              </RekamMedisState>
            </PerawatanState>
          </RuangState>
        </PasienState>
      </DokterState>
    </GlobalState>
  );
};

export default Providers;
