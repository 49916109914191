import React, {
  useContext,
  useEffect,
  useState,
  useMemo,
  useRef,
  useCallback,
} from "react";
// import "./Tooth.css";
import {
  IoReturnDownForward,
  IoReturnUpForwardOutline,
  IoTriangle,
  IoTriangleOutline,
} from "react-icons/io5";
import {
  HiArrowLongLeft,
  HiArrowLongRight,
  HiOutlineMinus,
} from "react-icons/hi2";
import { GrBottomCorner, GrTopCorner } from "react-icons/gr";
import { GlobalContext } from "../contexts/GlobalState";
import { TypeGigi } from "../utils/types";
import { getKuadran } from "../utils/functions";

const positionStyle = {
  left: {
    top: "50%",
    left: "0",
    transform: "translate(-50%, -50%) rotate(45deg)",
    borderRight: "2px solid black",
  },
  right: {
    top: "50%",
    right: "0",
    transform: "translate(50%, -50%) rotate(45deg)",
    borderLeft: "2px solid black",
  },
  top: {
    top: "0",
    left: "50%",
    transform: "translate(-50%, -50%) rotate(45deg)",
    borderBottom: "2px solid black",
  },
  bottom: {
    bottom: "0",
    left: "50%",
    transform: "translate(-50%, 50%) rotate(45deg)",
    borderTop: "2px solid black",
  },
};
const positionStyleGigiDepanBorder = {
  left: {
    top: "0",
    left: "0",
    transform: "translateX(-75%)",
    clipPath: "polygon(75% 0%, 100% 50%, 75% 100%, 25% 100%, 0% 50%, 25% 0%)",
    zIndex: "1",
  },
  right: {
    top: "0",
    right: "0",
    transform: "translateX(75%)",
    clipPath: "polygon(75% 0%, 100% 50%, 75% 100%, 25% 100%, 0% 50%, 25% 0%)",
    zIndex: "1",
  },
};

const positionStyleGigiDepan = {
  left: {
    top: "0",
    left: "0",
    // transform: "translateX(-75%)",
    clipPath: "polygon(73% 2%, 98% 50%, 73% 98%, 27% 98%, 2% 50%, 27% 2%)",
    zIndex: "1",
  },
  right: {
    top: "0",
    right: "0",
    // transform: "translateX(75%)",
    clipPath: "polygon(73% 2%, 98% 50%, 73% 98%, 27% 98%, 2% 50%, 27% 2%)",
    zIndex: "1",
  },
  top: {
    top: "0",
    transform: "translateY(-50%)",
    borderBottom: "2px solid black",
  },
  bottom: {
    top: "0",
    transform: "translateY(50%)",
    borderTop: "2px solid black",
  },
};

const useResizeParent = (id: string) => {
  const [size, setSize] = useState([0, 0]);
  useEffect(() => {
    const element = document?.querySelector(`#${id}`);
    const getSize = () => {
      setSize([element.clientWidth, element.clientHeight]);
    };
    if (element) {
      getSize();
      element.addEventListener("resize", getSize);
    }
    return () => element?.removeEventListener("resize", getSize);
  }, []);
  return size;
};

const ToothCondition: React.FC<{ kuadran: number; gigi: TypeGigi }> = ({
  kuadran,
  gigi,
}) => {
  const containerId = useMemo(() => {
    return "parent-" + new Date().getTime();
  }, []);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [parentWidth, _] = useResizeParent(containerId);
  return (
    <div
      id={containerId}
      // key={new Date().toISOString()}
      className="w-full h-full absolute top-0 left-0 overflow-visible"
      style={{ zIndex: "2", pointerEvents: "none" }}
    >
      {gigi.fractured ? (
        <div
          className="absolute w-full h-full top-0 left-0 flex text-center items-center justify-center text-biruMuda"
          style={{ fontSize: `${parentWidth * 1.3}px` }}
        >
          #
        </div>
      ) : null}
      {gigi.mahkotaLogam ? (
        <div className="absolute w-full h-full top-0 left-0 flex text-center items-center justify-center border-4 border-solid border-black"></div>
      ) : null}
      {gigi.mahkotaNonLogam ? (
        <div
          className="absolute w-full h-full top-0 left-0 flex text-center items-center justify-center border-4 border-solid border-black"
          style={{
            background:
              "repeating-linear-gradient(90deg, transparent, transparent 4px, black 4px, black 8px)",
            backgroundSize: "5px 100%",
          }}
        ></div>
      ) : null}
      {gigi.sisaAkar ? (
        <div
          className="absolute w-full h-full -top-1 left-0 flex text-center items-center justify-center text-biru"
          style={{ fontSize: `${parentWidth * 1.3}px` }}
        >
          √
        </div>
      ) : null}
      {gigi.gigiHilang ? (
        <div
          className="absolute w-full h-full -top-1 left-0 flex text-center items-center justify-center text-red-500"
          style={{ fontSize: `${parentWidth * 1.3}px` }}
        >
          X
        </div>
      ) : null}

      {gigi.lainnya ? (
        <div
          className="absolute w-full h-full -top-1 left-0 flex text-center items-center justify-center text-yellow-500"
          style={{ fontSize: `${parentWidth * 1.3}px` }}
        >
          O
        </div>
      ) : null}

      {gigi.une ? (
        <div
          className="absolute w-full h-fit top-0 left-0 flex text-center items-center justify-center font-bold"
          style={{
            fontSize: `${parentWidth * 0.3}px`,
            ...(kuadran === 1 || kuadran === 2
              ? {
                  top: "0",
                  left: "50%",
                  transform: `translate(-50%, -${parentWidth * 0.4 + 35}px)`,
                }
              : {
                  bottom: "0",
                  left: "50%",
                  transform: `translate(-50%, ${parentWidth * 0.9 + 35}px)`,
                }),
          }}
        >
          UNE
        </div>
      ) : null}

      {gigi.pre ? (
        <div
          className="absolute w-full h-fit top-0 left-0 flex text-center items-center justify-center font-bold"
          style={{
            fontSize: `${parentWidth * 0.3}px`,
            ...(kuadran === 1 || kuadran === 2
              ? {
                  top: "0",
                  left: "50%",
                  transform: `translate(-50%, -${parentWidth * 0.4 + 35}px)`,
                }
              : {
                  bottom: "0",
                  left: "50%",
                  transform: `translate(-50%, ${parentWidth * 0.9 + 35}px)`,
                }),
          }}
        >
          PRE
        </div>
      ) : null}

      {gigi.ano ? (
        <div
          className="absolute w-full h-fit top-0 left-0 flex text-center items-center justify-center font-bold"
          style={{
            fontSize: `${parentWidth * 0.3}px`,
            ...(kuadran === 1 || kuadran === 2
              ? {
                  top: "0",
                  left: "50%",
                  transform: `translate(-50%, -${parentWidth * 0.4 + 35}px)`,
                }
              : {
                  bottom: "0",
                  left: "50%",
                  transform: `translate(-50%, ${parentWidth * 0.9 + 35}px)`,
                }),
          }}
        >
          ANO
        </div>
      ) : null}

      {gigi.perawatanSalAkar ? (
        <IoTriangle
          className="absolute w-1/2 text-black"
          size={parentWidth * 0.8}
          style={{
            ...(kuadran === 1 || kuadran === 2
              ? {
                  bottom: "0",
                  left: "50%",
                  transform: "translate(-50%, 73%) scaleY(-1)",
                }
              : {
                  top: "0",
                  left: "50%",
                  transform: "translate(-50%, -73%)",
                }),
          }}
        />
      ) : null}
      {gigi.nonVital ? (
        <IoTriangleOutline
          className="absolute w-1/2 text-black"
          size={parentWidth * 0.8}
          style={{
            ...(kuadran === 1 || kuadran === 2
              ? {
                  bottom: "0",
                  left: "50%",
                  transform: "translate(-50%, 73%) scaleY(-1)",
                }
              : { top: "0", left: "50%", transform: "translate(-50%, -73%)" }),
          }}
        />
      ) : null}

      {gigi.jembatan === "kiri" ? (
        <GrTopCorner
          className="absolute w-1/2 text-black "
          size={parentWidth * 0.8}
          style={{
            ...(kuadran === 1 || kuadran === 2
              ? {
                  top: "0",
                  right: "0",
                  transform: "translate(0, -60%)",
                }
              : {
                  bottom: "0",
                  right: "0",
                  transform: "translate(0, 60%) scaleY(-1)",
                }),
          }}
        />
      ) : gigi.jembatan === "kanan" ? (
        <GrBottomCorner
          className="absolute w-1/2 text-black "
          size={parentWidth * 0.8}
          style={{
            ...(kuadran === 1 || kuadran === 2
              ? {
                  top: "0",
                  left: "0",
                  transform: "translate(0, -60%) scaleY(-1)",
                }
              : { bottom: "0", left: "0", transform: "translate(0, 60%)" }),
          }}
        />
      ) : gigi.jembatan === "tengah" ? (
        <HiOutlineMinus
          className="absolute w-full text-black "
          size={parentWidth * 0.8}
          style={{
            ...(kuadran === 1 || kuadran === 2
              ? {
                  top: "0",
                  left: "50%",
                  transform: "translate(-50%, -80%)",
                }
              : {
                  bottom: "0",
                  left: "50%",
                  transform: "translate(-50%, 80%)",
                }),
          }}
        />
      ) : null}

      {gigi.migrasi === "kiri" ? (
        <HiArrowLongLeft
          className="absolute w-full text-black "
          size={parentWidth * 0.8}
          style={{
            ...(kuadran === 1 || kuadran === 2
              ? {
                  bottom: "0",
                  left: "50%",
                  transform: "translate(-50%, 73%)",
                }
              : { top: "0", left: "50%", transform: "translate(-50%, -73%)" }),
          }}
        />
      ) : gigi.migrasi === "kanan" ? (
        <HiArrowLongRight
          className="absolute w-full text-black "
          size={parentWidth * 0.8}
          style={{
            ...(kuadran === 1 || kuadran === 2
              ? {
                  bottom: "0",
                  left: "50%",
                  transform: "translate(-50%, 73%)",
                }
              : { top: "0", left: "50%", transform: "translate(-50%, -73%)" }),
          }}
        />
      ) : gigi.migrasi === "atas" ? (
        <IoReturnUpForwardOutline
          className="absolute w-full text-black "
          size={parentWidth * 0.8}
          style={{ top: "0", left: "50%", transform: "translate(-50%, -73%)" }}
        />
      ) : gigi.migrasi === "bawah" ? (
        <IoReturnDownForward
          className="absolute w-full text-black "
          size={parentWidth * 0.8}
          style={{
            bottom: "0",
            left: "50%",
            transform: "translate(-50%, 73%)",
          }}
        />
      ) : null}
    </div>
  );
};

const isGigiDepan = (gigi: TypeGigi) =>
  (gigi.no_gigi >= 11 && gigi.no_gigi <= 13) ||
  (gigi.no_gigi >= 21 && gigi.no_gigi <= 23) ||
  (gigi.no_gigi >= 51 && gigi.no_gigi <= 53) ||
  (gigi.no_gigi >= 61 && gigi.no_gigi <= 63) ||
  (gigi.no_gigi >= 81 && gigi.no_gigi <= 83) ||
  (gigi.no_gigi >= 71 && gigi.no_gigi <= 73) ||
  (gigi.no_gigi >= 41 && gigi.no_gigi <= 43) ||
  (gigi.no_gigi >= 31 && gigi.no_gigi <= 33);

const Tooth: React.FC<{
  detailPreview?: boolean;
  gigi: TypeGigi;
  style?: any;
  openEditSingleGigiModal?: (position: string) => void;
}> = ({ detailPreview, gigi, style, openEditSingleGigiModal }) => {
  const { useResize } = useContext(GlobalContext);
  const size = useResize();
  const gigiDepan = isGigiDepan(gigi);
  const kuadran = getKuadran(gigi);
  // const [isMounted, toggle] = useReducer((p) => !p, true);
  const [toothRef, setToothRef] = useState<DOMRect>(null);

  const handleRect = useCallback((node) => {
    setToothRef(node?.getBoundingClientRect());
  }, []);
  const mesialRef = useRef<HTMLDivElement>(null);
  const distalRef = useRef<HTMLDivElement>(null);
  const oklusalRef = useRef<HTMLDivElement>(null);
  const vestibularRef = useRef<HTMLDivElement>(null);
  const lingualRef = useRef<HTMLDivElement>(null);

  // const lineWidth = toothRef?.current?.clientWidth / Math.sqrt(2) || 0;
  const lineWidth = useMemo(() => {
    if (toothRef) {
      const newWidth = toothRef.width / Math.sqrt(2);
      return newWidth;
    } else {
      return 0;
    }
  }, [toothRef, gigi, size]);

  const singleToothList = [
    { name: "mesial", ref: mesialRef },
    { name: "distal", ref: distalRef },
    { name: "oklusal", ref: oklusalRef },
    { name: "lingual", ref: lingualRef },
    { name: "vestibular", ref: vestibularRef },
  ];
  useEffect(() => {
    singleToothList.forEach((item) => {
      if (item.ref.current) {
        item.ref.current.classList.remove(
          `bg-caries`,
          `bg-tambalanLogam`,
          `bg-tambalanNonLogam`
        );
        if (gigi[item.name]?.caries) {
          item.ref.current.classList.add(`bg-caries`);
        }
        if (gigi[item.name]?.tambalanLogam) {
          item.ref.current.classList.add(`bg-tambalanLogam`);
        }
        if (gigi[item.name]?.tambalanNonLogam) {
          item.ref.current.classList.add(`bg-tambalanNonLogam`);
        }
      }
    });
  }, [gigi]);

  const checkExistingBg = (position: string) => {
    return (
      gigi?.[position]?.caries ||
      gigi?.[position]?.tambalanLogam ||
      gigi?.[position]?.tambalanNonLogam
    );
  };

  if (!gigiDepan) {
    return (
      <div
        ref={handleRect}
        className="w-1/35-screen min-w-10 aspect-square relative overflow-visible"
        style={style}
      >
        <ToothCondition kuadran={kuadran} gigi={gigi} />
        <div className="w-full h-full aspect-square border-solid border-2 border-black relative overflow-hidden">
          <div
            ref={mesialRef}
            onClick={() => openEditSingleGigiModal?.("mesial")}
            className={`absolute transition-all z-0 ${
              detailPreview ? "hover:bg-gray-300 cursor-pointer" : ""
            }`}
            style={{
              width: `${lineWidth}px`,
              height: `${lineWidth}px`,
              ...(kuadran === 1 || kuadran === 4
                ? positionStyle.right
                : positionStyle.left),
            }}
          ></div>

          <div
            ref={distalRef}
            onClick={() => openEditSingleGigiModal?.("distal")}
            className={`absolute transition-all z-0 ${
              detailPreview ? "hover:bg-gray-300  cursor-pointer" : ""
            }`}
            style={{
              width: `${lineWidth}px`,
              height: `${lineWidth}px`,
              ...(kuadran === 1 || kuadran === 4
                ? positionStyle.left
                : positionStyle.right),
            }}
          ></div>
          <div
            ref={oklusalRef}
            onClick={() => openEditSingleGigiModal?.("oklusal")}
            className={`w-1/2 h-1/2 absolute border-solid border-2 border-black transition-all ${
              checkExistingBg("oklusal") ? "" : "bg-white"
            } ${detailPreview ? "hover:bg-gray-300  cursor-pointer" : ""}`}
            style={{
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              zIndex: "1",
            }}
          ></div>
          <div
            ref={vestibularRef}
            onClick={() => openEditSingleGigiModal?.("vestibular")}
            className={`absolute transition-all z-0 ${
              detailPreview ? "hover:bg-gray-300  cursor-pointer" : ""
            }`}
            style={{
              width: `${lineWidth}px`,
              height: `${lineWidth}px`,
              ...(kuadran === 1 || kuadran === 2
                ? positionStyle.top
                : positionStyle.bottom),
            }}
          ></div>
          <div
            ref={lingualRef}
            onClick={() => openEditSingleGigiModal?.("lingual")}
            className={`absolute transition-all z-0 ${
              detailPreview ? "hover:bg-gray-300  cursor-pointer" : ""
            }`}
            style={{
              width: `${lineWidth}px`,
              height: `${lineWidth}px`,
              ...(kuadran === 1 || kuadran === 2
                ? positionStyle.bottom
                : positionStyle.top),
            }}
          ></div>
        </div>
      </div>
    );
  } else {
    return (
      <div
        ref={handleRect}
        className="w-1/35-screen min-w-10 aspect-square relative overflow-visible"
        style={style}
      >
        <ToothCondition kuadran={kuadran} gigi={gigi} />
        <div className="w-full h-full aspect-square border-solid border-2 border-black relative overflow-hidden">
          <div
            className="absolute bg-black z-0"
            style={{
              width: "100%",
              height: "100%",
              ...(kuadran === 1 || kuadran === 4
                ? positionStyleGigiDepanBorder.right
                : positionStyleGigiDepanBorder.left),
            }}
          >
            <div
              ref={mesialRef}
              onClick={() => openEditSingleGigiModal?.("mesial")}
              className={`absolute transition-all box-border bg-white ${
                detailPreview ? "hover:bg-gray-300  cursor-pointer" : ""
              }`}
              style={{
                width: "100%",
                height: "100%",
                zIndex: "1",
                ...(kuadran === 1 || kuadran === 4
                  ? positionStyleGigiDepan.right
                  : positionStyleGigiDepan.left),
              }}
            ></div>
          </div>
          <div
            className="absolute bg-black z-0"
            style={{
              width: "100%",
              height: "100%",
              ...(kuadran === 1 || kuadran === 4
                ? positionStyleGigiDepanBorder.left
                : positionStyleGigiDepanBorder.right),
            }}
          >
            <div
              ref={distalRef}
              onClick={() => openEditSingleGigiModal?.("distal")}
              className={`absolute transition-all box-border bg-white ${
                detailPreview ? "hover:bg-gray-300  cursor-pointer" : ""
              }`}
              style={{
                width: "100%",
                height: "100%",
                zIndex: "1",
                ...(kuadran === 1 || kuadran === 4
                  ? positionStyleGigiDepan.left
                  : positionStyleGigiDepan.right),
              }}
            ></div>
          </div>
          {/* <div
          ref={oklusalRef}
          className="w-3/5 h-1/5 absolute border-solid border-2 border-black transition-all z-10"
          style={{
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        ></div> */}
          <div
            ref={vestibularRef}
            onClick={() => openEditSingleGigiModal?.("vestibular")}
            className={`absolute transition-all z-0 ${
              detailPreview ? "hover:bg-gray-300  cursor-pointer" : ""
            }`}
            style={{
              width: "100%",
              height: "100%",
              ...(kuadran === 1 || kuadran === 2
                ? positionStyleGigiDepan.top
                : positionStyleGigiDepan.bottom),
            }}
          ></div>
          <div
            ref={lingualRef}
            onClick={() => openEditSingleGigiModal?.("lingual")}
            className={`absolute transition-all z-0 ${
              detailPreview ? "hover:bg-gray-300  cursor-pointer" : ""
            }`}
            style={{
              width: "100%",
              height: "100%",
              ...(kuadran === 1 || kuadran === 2
                ? positionStyleGigiDepan.bottom
                : positionStyleGigiDepan.top),
            }}
          ></div>
        </div>
      </div>
    );
  }
};

export default Tooth;
