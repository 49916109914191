import { useState, useRef, useCallback } from "react";
import { Select } from "antd";

const SelectWithAdd = (props) => {
  const { options: baseOptions, ...selectProps } = props;

  const [options, setOptions] = useState([...baseOptions]);

  const currentValue = useRef();

  const onSearch = useCallback(
    (value) => {
      currentValue.current = value;
      const filtered = baseOptions.filter((item) =>
        item.label?.toLowerCase?.().includes(value?.toLowerCase?.())
      );

      if (value) {
        setOptions([...filtered, { label: value, value: value }]);
      } else {
        setOptions([...baseOptions]);
      }
    },
    [baseOptions]
  );

  return (
    <Select
      {...selectProps}
      showSearch
      optionFilterProp="children"
      filterOption={(input: string, option: any) => {
        return (option?.label?.props?.children ?? "")
          .toLowerCase()
          .includes((input || "").toLowerCase());
      }}
      onSearch={onSearch}
      options={options.map((item) => ({
        ...item,
        label: <p className="whitespace-pre-wrap">{item.label}</p>,
      }))}
    />
  );
};

export default SelectWithAdd;
