import { useState, useContext, useEffect } from "react";
import { BsFillPersonPlusFill } from "react-icons/bs";
import { Button, Input, Tooltip, Modal } from "antd";
import { debounce } from "lodash";
import { DokterContext } from "../contexts/DokterState";
import { TypeDokter, TypeFilterTable, TypeTableColumn } from "../utils/types";
import CustomTable from "../components/CustomTable";
import { BiEdit, BiTrash } from "react-icons/bi";
import { GlobalContext } from "../contexts/GlobalState";
import {
  FAILED_DISPLAY_MESSAGE,
  SIZE_COLUMN_LG,
  SIZE_COLUMN_XL,
  SIZE_COLUMN_XS,
} from "../utils/constants.tsx";
import AddDataModal from "../components/AddDataModal";
import { filteredColumns } from "../utils/functions";

const { Search } = Input;
const { confirm } = Modal;

const Dokter: React.FC = () => {
  const [componentDidMount, setComponentDidMount] = useState(false);
  const { user, showLoadingMessage, showSuccessMessage, showErrorMessage } =
    useContext(GlobalContext);
  const {
    dokterList,
    pagination,
    isLoadingGet,
    getDokter,
    addDokter,
    editDokter,
    deleteDokter,
  } = useContext(DokterContext);
  const [filter, setFilter] = useState<TypeFilterTable<TypeDokter>>({
    page: 1,
    sortField: "nama",
    isAsc: true,
  });
  const [searchQuery, setSearchQuery] = useState("");
  const getData = (page?: number) => {
    getDokter({
      page: page || filter.page,
      query: searchQuery,
      sort_field: filter.sortField,
      is_asc: filter.isAsc,
    });
  };
  const getDataDebounce = debounce((e) => {
    setSearchQuery(e?.target?.value);
  }, 500);

  // Perform get data
  useEffect(() => {
    getData();
    setComponentDidMount(true);
  }, []);

  useEffect(() => {
    if (componentDidMount) {
      getData();
    }
  }, [filter]);

  useEffect(() => {
    if (componentDidMount) {
      getData(1);
    }
  }, [searchQuery]);

  const [editingItem, setEditingItem] = useState<TypeDokter>(null);

  const dokterColumns: TypeTableColumn<TypeDokter>[] = [
    {
      title: "No",
      dataIndex: "no",
      align: "center",
      width: SIZE_COLUMN_XS,
    },
    {
      title: "Nama",
      dataIndex: "nama",
      sorter: true,
      editable: true,
      width: SIZE_COLUMN_XL,
    },
    {
      title: "Spesialis",
      dataIndex: "spesialisasi",
      sorter: true,
      editable: true,
      width: SIZE_COLUMN_XL,
    },
    {
      title: "Alamat",
      dataIndex: "alamat",
      editable: true,
      className: "min-w-32",
    },
    {
      title: "No HP",
      dataIndex: "no_hp",
      editable: true,
      width: SIZE_COLUMN_XL,
    },
    {
      title: "Aksi",
      align: "center",
      render: (text: string, item: TypeDokter) => (
        <div className="flex gap-3 items-center justify-center">
          <Tooltip title="Edit">
            <Button
              type="primary"
              className="flex items-center justify-center p-1"
              onClick={() => setEditingItem(item)}
            >
              <BiEdit size={20} />
            </Button>
          </Tooltip>
          <Tooltip title="Hapus">
            <Button
              type="primary"
              className="flex items-center justify-center p-1"
              onClick={(e) => {
                e.preventDefault();
                confirm({
                  title: `Apakah Anda yakin ingin menghapus dokter : ${item.nama}?`,
                  icon: null,
                  content:
                    "Data yang sudah dihapus tidak dapat dikembalikan lagi.",
                  okButtonProps: { type: "primary", danger: true },
                  okText: "Hapus",
                  cancelButtonProps: { type: "primary" },
                  cancelText: "Batal",
                  onOk() {
                    deleteData(item.id);
                  },
                  maskClosable: true,
                });
              }}
              danger
            >
              <BiTrash size={20} />
            </Button>
          </Tooltip>
        </div>
      ),
      width: SIZE_COLUMN_LG,
    },
  ];

  const addData = async (data: TypeDokter) => {
    const res = await addDokter(data);
    if (res?.data) {
      getData();
    }
    return res;
  };
  const editData = async (data: Partial<TypeDokter>) => {
    return await editDokter({ ...data, id: editingItem.id });
  };
  const deleteData = async (id: number) => {
    const messageKey = new Date().toISOString();
    showLoadingMessage(messageKey);
    const res = await deleteDokter(id);
    if (res?.success) {
      showSuccessMessage(messageKey, "Data berhasil dihapus");
    } else {
      showErrorMessage(
        messageKey,
        res.displayMessage || FAILED_DISPLAY_MESSAGE
      );
    }
  };

  const [modalOpen, setModalOpen] = useState(false);
  return (
    <div className="w-full flex flex-col p-6 gap-6 items-center">
      <div className="w-full rounded-lg bg-white overflow-hidden flex p-4 flex-col gap-4 shadow-md transition-all">
        <h3>Data Dokter</h3>
        <div className="w-full flex justify-between items-center gap-4">
          <div className="flex gap-4 items-center">
            <Search
              placeholder="Cari nama, alamat, dll"
              onChange={getDataDebounce}
              className="w-auto md:w-96"
            />
          </div>
          {user?.role === "SUPERADMIN" ? (
            <Button
              type="primary"
              className="flex items-center gap-2"
              onClick={() => setModalOpen(true)}
            >
              <BsFillPersonPlusFill size={20} />
              Tambah Dokter
            </Button>
          ) : null}
        </div>
        <CustomTable<TypeDokter>
          pagination={pagination}
          dataList={dokterList}
          columns={filteredColumns(user, dokterColumns)}
          isLoading={isLoadingGet}
          setFilter={setFilter}
          filter={filter}
          editingItem={editingItem}
          setEditingItem={setEditingItem}
          editData={editData}
        />
      </div>
      <AddDataModal<TypeDokter>
        title="Tambah Dokter"
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        columns={dokterColumns.filter((item) => item.editable)}
        addData={addData}
      />
    </div>
  );
};

export default Dokter;
