import { PropsWithChildren, createContext, useEffect, useState } from "react";
import { TypeGlobalContext, TypeUser } from "../utils/types";
import axios from "axios";
import { message } from "antd";
import { useNavigate } from "react-router-dom";
import { FAILED_DATA, FAILED_DISPLAY_MESSAGE } from "../utils/constants.tsx";
import { host } from "./config";

axios.defaults.withCredentials = true;
const initialState: TypeGlobalContext = {
  user: null,
  userList: [],
  summary: {
    pasienCount: 0,
    dokterCount: 0,
    perawatanCount: 0,
    ruangCount: 0,
    history: [],
  },
  pagination: {
    current: 1,
    pageSize: 10,
    total: 0,
  },
  isLoadingGet: false,
  isLoadingSummary: false,
};
export const GlobalContext = createContext<TypeGlobalContext>(initialState);
const GlobalState: React.FC<PropsWithChildren> = ({ children }) => {
  const [messageApi, messageContextHolder] = message.useMessage();
  const navigate = useNavigate();
  const showSuccessMessage = (key: string, content: string) => {
    messageApi.success({
      key,
      content,
      duration: 4,
    });
  };
  const showLoadingMessage = (key: string) => {
    messageApi.loading({
      key,
      content: "Loading...",
      duration: 0,
    });
  };
  const showErrorMessage = (key: string, content: string) => {
    messageApi.error({
      key,
      content,
      duration: 4,
    });
  };

  const [state, setState] = useState<TypeGlobalContext>(initialState);
  const dispatch = (payload: Partial<TypeGlobalContext>) => {
    setState((prevState) => ({
      ...prevState,
      ...payload,
    }));
  };

  const login = async (username: string, password: string) => {
    try {
      const res = await axios.post(host + "/auth/login.php", {
        username,
        password,
      });
      if (res.data.success) {
        return { ...res.data, displayMessage: "Login berhasil" };
      } else {
        return {
          ...res.data,
          displayMessage: res.data.displayMessage || FAILED_DISPLAY_MESSAGE,
        };
      }
    } catch (err) {
      return FAILED_DATA;
    }
  };

  const logout = async () => {
    try {
      await axios.get(host + "/auth/logout.php");
      dispatch(initialState);
      navigate("/login");
    } catch (err) {
      return FAILED_DATA;
    }
  };

  const getUserData = async () => {
    try {
      const res = await axios.get(host + "/user/get_user_data.php");
      if (res.data.success) {
        dispatch({ user: res.data.data });
      }
      return res.data;
    } catch (err) {
      return FAILED_DATA;
    }
  };

  const getUser = async (data: {
    page?: number;
    query?: string;
    sort_field?: string;
    is_asc?: boolean;
  }) => {
    dispatch({ isLoadingGet: true });
    try {
      const res = await axios.get(host + "/user/get_user.php", {
        params: data,
      });
      if (res.data.success) {
        dispatch({
          userList: res.data.data || [],
          pagination: res.data.pagination,
        });
      }
      return res.data;
    } catch (err) {
      return FAILED_DATA;
    } finally {
      dispatch({ isLoadingGet: false });
    }
  };

  const addUser = async (data: TypeUser) => {
    try {
      const res = await axios.post(host + "/user/add_user.php", data);
      return res.data;
    } catch (err) {
      return FAILED_DATA;
    }
  };

  const editUser = async (data: Partial<TypeUser>) => {
    try {
      const res = await axios.post(host + "/user/edit_user.php", data);

      const prevIndex = state.userList.findIndex((item) => item.id === data.id);
      if (prevIndex !== -1) {
        const newUserList = [...state.userList];
        newUserList[prevIndex] = {
          ...newUserList[prevIndex],
          ...data,
        };
        dispatch({ userList: newUserList });
      }

      return res.data;
    } catch (err) {
      return FAILED_DATA;
    }
  };

  const deleteUser = async (id: number) => {
    try {
      const res = await axios.post(host + "/user/delete_user.php", { id });

      const prevIndex = state.userList.findIndex((item) => item.id === id);
      if (prevIndex !== -1) {
        const newUserList = [...state.userList];
        newUserList.splice(prevIndex, 1);
        dispatch({ userList: newUserList });
      }

      return res.data;
    } catch (err) {
      return FAILED_DATA;
    }
  };

  const getSummary = async () => {
    dispatch({ isLoadingSummary: true });
    try {
      const res = await axios.get(host + "/user/get_summary.php");
      if (res.data.success) {
        dispatch({
          summary: res.data.data || {},
        });
      }
      return res.data;
    } catch (err) {
      return FAILED_DATA;
    } finally {
      dispatch({ isLoadingSummary: false });
    }
  };
  const useResize = () => {
    const [size, setSize] = useState([0, 0]);
    useEffect(() => {
      const getSize = () => setSize([window.innerWidth, window.innerHeight]);
      getSize();
      window.addEventListener("resize", getSize);
      return () => window.removeEventListener("resize", getSize);
    }, []);
    return size;
  };

  return (
    <GlobalContext.Provider
      value={{
        ...state,
        showSuccessMessage,
        showLoadingMessage,
        showErrorMessage,
        login,
        logout,
        getUserData,
        getUser,
        addUser,
        editUser,
        deleteUser,
        getSummary,
        useResize,
      }}
    >
      {messageContextHolder}
      {children}
    </GlobalContext.Provider>
  );
};

export default GlobalState;
