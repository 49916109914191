import dayjs from "dayjs";
import { DATE_FORMAT, GENDER_OPTIONS, mappedColumns } from "./constants.tsx";
import { utils, write } from "xlsx-js-style";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import {
  TypeGigi,
  TypePasien,
  TypePerawatanPemeriksaan,
  TypeRekamMedis,
  TypeUser,
} from "./types";

export const isoStringToAge: (isostring: any) => number = (isostring) => {
  const birthDate = dayjs(isostring);

  const currentDate = dayjs();
  const ageInMilliseconds = currentDate.diff(birthDate);
  const ageInYears = ageInMilliseconds / (1000 * 60 * 60 * 24 * 365.25);

  return Math.floor(ageInYears);
};

export const formatToNoonISOString = (date: any) => {
  const noonDate = dayjs(date)
    .set("hour", 12)
    .set("minute", 0)
    .set("second", 0)
    .set("millisecond", 0);
  const isoString = noonDate.toISOString();
  return isoString;
};

export const formatIDRPrice: (price: number) => string = (price) => {
  const priceString = price.toLocaleString("id-ID");
  const formattedPrice = `Rp ${priceString}`;

  return formattedPrice;
};

export const generateArray = (n: number) => {
  return Array.from({ length: n }, (_, i) => i + 1);
};

export const downloadExcel = (
  columnOption: any[],
  dataList: any[],
  fileName: string
) => {
  dataList = dataList.map((item, index) => ({
    ...item,
    no: index + 1,
    tgl_periksa: dayjs(item.tgl_periksa).format(DATE_FORMAT),
  }));
  const usedColumnList = columnOption.map((item) => ({
    title: item.title,
    dataIndex: item.dataIndex,
  }));
  dataList = dataList.map((item) => {
    let usedData = {};
    usedColumnList.forEach((item2) => {
      if (mappedColumns.map((item3) => item3.name).includes(item2.dataIndex)) {
        const columnItem = mappedColumns.find(
          (item3) => item3.name === item2.dataIndex
        );
        const attributeName = columnItem.attributeName;
        const attributeJumlah = columnItem.attributeJumlah;
        usedData[item2.title] = item[item2.dataIndex]
          .map((item3) => {
            if (attributeJumlah === undefined) {
              return "• " + item3[attributeName];
            } else {
              return `• ${item3[attributeName]} (${item3[attributeJumlah]})`;
            }
          })
          .join("\n");
      } else {
        usedData[item2.title] = item[item2.dataIndex];
      }
    });
    return usedData;
  });
  // Create a new workbook
  const wb = utils.book_new();

  // Create a worksheet
  const ws = utils.json_to_sheet(dataList);

  // Set column widths
  ws["!cols"] = columnOption.map((col) => ({ wch: (col.width || 200) / 10 }));

  // Add the worksheet to the workbook
  utils.book_append_sheet(wb, ws, "Sheet1");

  for (const cellAddress in ws) {
    if (cellAddress.startsWith("!")) continue; // Skip header cells

    // Set the wrap_text property for the cell
    ws[cellAddress].s = { alignment: { wrapText: true, vertical: "top" } };
  }

  // Generate a blob containing the workbook data
  const xlsxBinaryData = write(wb, {
    bookType: "xlsx",
    type: "binary", // Use 'binary' type to get binary data
  });

  // Convert the binary string to Blob
  const blob = new Blob([s2ab(xlsxBinaryData)], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  });

  // Create a download link and trigger the download
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.href = url;
  a.download = `${fileName}.xlsx`; // Set the filename
  document.body.appendChild(a);
  a.click();

  // Clean up
  window.URL.revokeObjectURL(url);
  document.body.removeChild(a);
};

export const getLatestMonths = (count: number = 5) => {
  const latestMonths = [];
  let currentDate = dayjs();

  for (let i = 0; i < count; i++) {
    const formattedDate = currentDate.format("YYYY-MM");
    latestMonths.push(formattedDate);

    currentDate = currentDate.subtract(1, "month");
  }

  return latestMonths.reverse();
};

export const sortFunction = (property: string) => {
  return (a, b) => a[property].localeCompare(b[property]);
};

export const s2ab = (s) => {
  const buf = new ArrayBuffer(s.length);
  const view = new Uint8Array(buf);
  for (let i = 0; i < s.length; i++) {
    view[i] = s.charCodeAt(i) & 0xff;
  }
  return buf;
};

export const totalPerawatanPrice = (perawatan: TypePerawatanPemeriksaan[]) => {
  let totalPrice = 0;
  perawatan.forEach((item) => {
    totalPrice += item.harga * item.jumlah;
  });
  return totalPrice;
};

export const filteredColumns = (user: TypeUser, columns: any[]) => {
  if (user?.role === "SUPERADMIN") {
    return columns;
  } else {
    return columns.filter((item) => item.title !== "Aksi");
  }
};

export const getLevel1Path = (pathname: string) => {
  const level1PathList = pathname.split("/");
  const level1Path = level1PathList[0] + "/" + (level1PathList[1] || "");
  return level1Path;
};

export const isMobile = () => {
  const toMatch = [
    /Android/i,
    /webOS/i,
    /iPhone/i,
    /iPad/i,
    /iPod/i,
    /BlackBerry/i,
    /Windows Phone/i,
    /Linux/i,
  ];

  const smallScreen = window?.outerWidth < 1024 || true;
  return (
    toMatch.some((toMatchItem) => {
      return navigator.userAgent.match(toMatchItem);
    }) && smallScreen
  );
};

export const downloadPDF = (pasien: TypePasien, data: TypeRekamMedis[]) => {
  var doc = new jsPDF("l");

  const head = [
    [
      "No",
      "Tanggal",
      "Nama Dokter",
      "Klinik",
      "Keluhan",
      "Diagnosa",
      "Perawatan",
      "Keterangan",
    ],
  ];
  const body = data.map((item, index) => [
    index + 1,
    dayjs(item.tgl_periksa).format(DATE_FORMAT),
    item.nama_dokter,
    item.ruang,
    item.keluhan,
    item.diagnosa,
    item.perawatan
      .map((item3) => {
        return `• ${item3.nama_perawatan} (${item3.jumlah})`;
      })
      .join("\n"),
    item.keterangan,
  ]);

  doc.setFont(undefined, "bold");
  doc.text("REKAM MEDIS", doc.internal.pageSize.getWidth() / 2, 15, {
    align: "center",
  });
  doc.setFontSize(10);
  doc.text(
    process.env.REACT_APP_APP_NAME,
    doc.internal.pageSize.getWidth() / 2,
    20,
    {
      align: "center",
    }
  );

  const infoPasien = [
    ["Nama", pasien.nama],
    ["No RM", pasien.no_rm],
    [
      "Jenis Kelamin",
      GENDER_OPTIONS.find((item) => item.value === pasien.jenis_kelamin).label,
    ],
    ["Alamat", pasien.alamat],
    ["Tgl Lahir", dayjs(pasien.tgl_lahir).format(DATE_FORMAT)],
    ["Riwayat Penyakit", pasien.riwayat_penyakit],
    ["Riwayat Alergi Obat", pasien.riwayat_alergi],
  ];
  autoTable(doc, {
    body: infoPasien,
    startY: 25,
    showHead: false,
    bodyStyles: {
      valign: "top",
      fillColor: [255, 255, 255],
      textColor: [0, 0, 0],
      lineColor: [0, 0, 0],
      lineWidth: 0.1,
    },
    columnStyles: {
      0: {
        cellWidth: 40,
      },
      1: {
        fontStyle: "bold",
      },
    },
  });
  autoTable(doc, {
    head: head,
    body: body,
    startY: (doc as any).lastAutoTable.finalY + 5,
    rowPageBreak: "auto",
    bodyStyles: {
      valign: "top",
      fillColor: [255, 255, 255],
      textColor: [0, 0, 0],
      lineColor: [0, 0, 0],
      lineWidth: 0.1,
      cellWidth: "auto",
    },
    headStyles: {
      fillColor: [255, 255, 255],
      textColor: [0, 0, 0],
      lineColor: [0, 0, 0],
      lineWidth: 0.1,
    },
    tableWidth: "auto",
  });

  doc.save(`Rekam Medis_${data[0]?.no_rm}.pdf`);

  return doc;
};

export const rangeToArrayGigi = (
  x: number,
  y: number,
  gigiList?: TypeGigi[]
) => {
  const result: TypeGigi[] = [];
  if (x < y)
    for (let i = x; i <= y; i++) {
      const gigi = gigiList.find((item) => item.no_gigi === i);
      result.push(gigi || { no_gigi: i });
    }
  else
    for (let i = x; i >= y; i--) {
      const gigi = gigiList.find((item) => item.no_gigi === i);
      result.push(gigi || { no_gigi: i });
    }
  return result;
};

export const checkInside = (x: number, y: number, z: number) => {
  if (x < y) return x <= z && z <= y;
  else return y <= z && z <= x;
};

export const getKuadran = (gigi: TypeGigi) => {
  if (checkInside(18, 11, gigi.no_gigi)) {
    return 1;
  } else;
  if (checkInside(21, 28, gigi.no_gigi)) {
    return 2;
  } else;
  if (checkInside(55, 51, gigi.no_gigi)) {
    return 1;
  } else;
  if (checkInside(61, 65, gigi.no_gigi)) {
    return 2;
  } else;

  if (checkInside(85, 81, gigi.no_gigi)) {
    return 4;
  } else;
  if (checkInside(71, 75, gigi.no_gigi)) {
    return 3;
  } else;
  if (checkInside(48, 41, gigi.no_gigi)) {
    return 4;
  } else;
  if (checkInside(31, 38, gigi.no_gigi)) {
    return 3;
  }
};

export const gigiToApiData = (data: TypeGigi) => {
  const newData = { ...data };
  const id = newData.id;
  delete newData.id;
  const no_rm = newData.no_rm;
  delete newData.no_rm;
  const no_gigi = newData.no_gigi;
  delete newData.no_gigi;

  const json_data = JSON.stringify(data);

  return {
    id,
    no_rm,
    no_gigi,
    json_data,
  };
};

export const apiDataToGigi = (data: {
  id: number;
  no_gigi: number;
  no_rm: string;
  json_data: string;
}) => {
  try {
    let objData: TypeGigi = JSON.parse(data.json_data);
    objData = {
      ...objData,
      id: data.id,
      no_rm: data.no_rm,
      no_gigi: data.no_gigi,
    };
    return objData;
  } catch (err) {}
  try {
    let objData: TypeGigi = JSON.parse(data.json_data.replace(/\\/g, ""));
    objData = {
      ...objData,
      id: data.id,
      no_rm: data.no_rm,
      no_gigi: data.no_gigi,
    };
    return objData;
  } catch (err) {}
};

export const jsonToFormData = (data: Object, arrayKeys: string[]) => {
  const formData = new FormData();
  Object.keys(data).forEach((key) => {
    if (arrayKeys.includes(key)) {
      if (Array.isArray(data[key]) && data[key][0] instanceof File) {
        // Handle array of files
        data[key].forEach((file, index) => {
          formData.append(`${key}[${index}]`, file);
        });
      } else {
        // Handle array of string/number
        formData.append(key, JSON.stringify(data[key]));
      }
    } else {
      formData.append(key, data[key]);
    }
  });

  return formData;
};
