import React, { useRef, useContext } from "react";
import { Form, Input, Button, Card, theme } from "antd";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { GlobalContext } from "../contexts/GlobalState";
import { REQUIRED_MESSAGE } from "../utils/constants.tsx";
import { useForm } from "antd/es/form/Form";

const Login: React.FC = () => {
  const navigate = useNavigate();
  const {
    showLoadingMessage,
    showSuccessMessage,
    showErrorMessage,
    login,
    getUserData,
  } = useContext(GlobalContext);

  const {
    token: { colorPrimaryBg },
  } = theme.useToken();
  const buttonRef = useRef<HTMLButtonElement>();
  const onFinish = async (values) => {
    const messageKey = new Date().toISOString();
    showLoadingMessage(messageKey);
    buttonRef.current.disabled = true;
    try {
      const res = await login(values.username, values.password);
      if (res.success) {
        showSuccessMessage(messageKey, res.displayMessage);
        await getUserData();
        navigate("/");
      } else {
        showErrorMessage(messageKey, res.displayMessage);
      }
    } catch (err) {}
    buttonRef.current.disabled = false;
  };
  const [loginForm] = useForm();
  return (
    <div
      style={{
        background: colorPrimaryBg,
      }}
      className="relative w-full h-full min-h-100/70-screen md:min-h-screen flex flex-col justify-center items-center gap-6"
    >
      <div className="ant-layout w-full h-full text-center absolute top-0 left-0 flex flex-col justify-start">
        <img
          className="object-cover h-full w-full absolute"
          src={`${process.env.PUBLIC_URL}/assets/images/background.jpg`}
          alt="background"
        />
        <div className="absolute w-full h-full bg-gray-800 opacity-40 top-0 left-0 z-0"></div>
      </div>
      <p className="text-5xl font-bold relative z-10 text-white">
        {process.env.REACT_APP_APP_NAME}
      </p>
      <p className="relative z-10 text-white">
        Silakan login untuk mengakses dashboard {process.env.REACT_APP_APP_NAME}
      </p>
      <Card
        title="Login"
        bordered={false}
        className="w-11/12 md:w-1/2 text-center"
      >
        <Form className="flex flex-col gap-4" onFinish={onFinish}>
          <Form.Item
            name="username"
            rules={[
              {
                required: true,
                message: REQUIRED_MESSAGE,
              },
            ]}
          >
            <Input
              prefix={<UserOutlined className="site-form-item-icon" />}
              placeholder="Username"
              onChange={(e) => {
                const value = (e.target?.value || "").replaceAll(" ", "");
                loginForm.setFieldValue("username", value);
              }}
            />
          </Form.Item>
          <Form.Item
            name="password"
            rules={[
              {
                required: true,
                message: REQUIRED_MESSAGE,
              },
            ]}
          >
            <Input.Password
              prefix={<LockOutlined className="site-form-item-icon" />}
              placeholder="Password"
            />
          </Form.Item>
          <Button ref={buttonRef} type="primary" htmlType="submit">
            Login
          </Button>
        </Form>
      </Card>
    </div>
  );
};

export default Login;
