import { useState, useContext, useEffect, useMemo } from "react";
import { FaFileMedical } from "react-icons/fa";
import {
  Button,
  Tooltip,
  Modal,
  Descriptions,
  Form,
  Row,
  Col,
  Input,
  Upload,
} from "antd";
import { RekamMedisContext } from "../../contexts/RekamMedisState";
import {
  TypeRekamMedis,
  TypeFilterTable,
  TypeTableColumn,
  TypePasien,
  TypeFile,
} from "../../utils/types";
import CustomTable, {
  ContentComponent,
  EditorComponent,
} from "../../components/CustomTable";
import {
  BiDownload,
  BiEdit,
  BiLeftArrowCircle,
  BiPrinter,
  BiSave,
  BiTrash,
  BiUpload,
} from "react-icons/bi";

import { GlobalContext } from "../../contexts/GlobalState";
import {
  DATE_FORMAT,
  FAILED_DISPLAY_MESSAGE,
  GENDER_OPTIONS,
  REQUIRED_MESSAGE,
  SIZE_COLUMN_LG,
  SIZE_COLUMN_XL,
  SIZE_COLUMN_XS,
} from "../../utils/constants.tsx";
import AddDataModal from "../../components/AddDataModal";
import {
  downloadPDF,
  formatIDRPrice,
  formatToNoonISOString,
  generateArray,
  isoStringToAge,
} from "../../utils/functions";
import dayjs from "dayjs";
import { Link, useLocation, useParams } from "react-router-dom";
import { PasienContext } from "../../contexts/PasienState";
import { DokterContext } from "../../contexts/DokterState";
import { RuangContext } from "../../contexts/RuangState";
import { PerawatanContext } from "../../contexts/PerawatanState";
import { useForm } from "antd/es/form/Form";
import { HiMagnifyingGlass } from "react-icons/hi2";
import PreviewModal from "../../components/PreviewModal";
import Odontogram from "../../components/Odontogram";
import { GigiContext } from "../../contexts/GigiState.tsx";
import Tooth from "../../components/Tooth.tsx";
import SelectWithAdd from "../../components/SelectWithAdd.tsx";

const { confirm } = Modal;

const DetailPasien: React.FC = () => {
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const urlRef = queryParams.get("ref");
  const { no_rm } = useParams();

  const [componentDidMount, setComponentDidMount] = useState(false);
  const { showLoadingMessage, showSuccessMessage, showErrorMessage } =
    useContext(GlobalContext);
  const { allDokterList, getAllDokter } = useContext(DokterContext);
  const { allRuangList, getAllRuang } = useContext(RuangContext);
  const { allPerawatanList, getAllPerawatan } = useContext(PerawatanContext);
  const {
    rekamMedisList,
    pagination,
    isLoadingGet,
    getRekamMedis,
    getAllRekamMedisByNoRM,
    addRekamMedis,
    editRekamMedis,
    deleteRekamMedis,
    printRekamMedis,
  } = useContext(RekamMedisContext);
  const {
    isLoadingGet: isLoadingGetPasien,
    currentPasien,
    getPasienByNoRM,
    editPasien,
  } = useContext(PasienContext);
  const [filter, setFilter] = useState<TypeFilterTable<TypeRekamMedis>>({
    page: 1,
    sortField: "tgl_periksa",
    isAsc: false,
  });

  const getData = (page?: number) => {
    getRekamMedis({
      page: page || filter.page,
      query: no_rm,
      sort_field: filter.sortField,
      is_asc: filter.isAsc,
    });
  };

  // Perform get data
  useEffect(() => {
    getData();
    getPasienByNoRM(no_rm);
    setComponentDidMount(true);
    getAllDokter();
    getAllRuang();
    getAllPerawatan();
    getGigiByNoRM(no_rm);
  }, []);

  useEffect(() => {
    if (componentDidMount) {
      getData();
    }
  }, [filter]);

  const infoList: TypeTableColumn<TypePasien>[] = currentPasien
    ? [
        {
          title: "Nama",
          dataIndex: "nama",
          value: currentPasien.nama,
          editable: true,
        },
        {
          title: "No RM",
          dataIndex: "no_rm",
          value: currentPasien.no_rm,
        },
        {
          title: "Jenis Kelamin",
          dataIndex: "jenis_kelamin",
          editable: true,
          columnOptions: GENDER_OPTIONS,
        },
        {
          title: "Tanggal Lahir",
          dataIndex: "tgl_lahir",
          value: dayjs(currentPasien.tgl_lahir).format(DATE_FORMAT),
          editable: true,
        },
        {
          title: "Umur",
          value: String(isoStringToAge(currentPasien.tgl_lahir)),
        },
        {
          title: "No HP",
          dataIndex: "no_hp",
          editable: true,
          value: currentPasien.no_hp,
        },
        {
          title: "Alamat",
          dataIndex: "alamat",
          editable: true,
          value: currentPasien.alamat,
        },
        {
          title: "Riwayat Penyakit",
          dataIndex: "riwayat_penyakit",
          editable: true,
          value: currentPasien.riwayat_penyakit,
          optional: true,
        },
        {
          title: "Riwayat Alergi Obat",
          dataIndex: "riwayat_alergi",
          editable: true,
          value: currentPasien.riwayat_alergi,
          optional: true,
        },
      ]
    : [];

  const rekamMedisColumns: TypeTableColumn<TypeRekamMedis>[] = [
    {
      title: "No",
      dataIndex: "no",
      align: "center",
      width: SIZE_COLUMN_XS,
    },
    {
      title: "Tgl Periksa",
      dataIndex: "tgl_periksa",
      sorter: true,
      width: SIZE_COLUMN_XL,
    },
    {
      title: "Nama Dokter",
      dataIndex: "nama_dokter",
      sorter: true,
      editable: true,
      editSelectItems: allDokterList.map((item) => ({
        label: `${item.nama} - ${item.spesialisasi}`,
        value: item.id,
      })),
      width: SIZE_COLUMN_XL,
      editIndex: "id_dokter",
    },
    {
      title: "Keluhan",
      dataIndex: "keluhan",
      editable: true,
      className: "min-w-32",
      hidden: true,
    },
    {
      title: "Diagnosa",
      dataIndex: "diagnosa",
      editable: true,
      className: "min-w-32",
      hidden: true,
    },
    {
      title: "Perawatan",
      dataIndex: "perawatan",
      editable: true,
      className: "min-w-32",
      editSelectItems: allPerawatanList.map((item) => {
        return {
          label: item.nama,
          value: item.id,
          harga: item.harga,
        };
      }),
      withQuantity: true,
      hidden: true,
      joinInitial: true,
      CustomComponentEditorRender: ({
        item,
        handleRemoveField,
        addForm,
        initialValue,
        quantityList,
      }) => {
        useEffect(() => {
          const initialFieldsValue = {
            [`${item.dataIndex}List`]: [],
            [`${item.dataIndex}Quantities`]: [],
            [`${item.dataIndex}Prices`]: [],
          };
          quantityList.forEach((item2, index) => {
            if (initialValue?.[index]) {
              initialFieldsValue[`${item.dataIndex}List`].push(
                initialValue?.[index]?.id_perawatan === 0
                  ? initialValue?.[index]?.nama_perawatan
                  : initialValue?.[index]?.id_perawatan
              );
              initialFieldsValue[`${item.dataIndex}Quantities`].push(
                initialValue?.[index]?.jumlah
              );
              initialFieldsValue[`${item.dataIndex}Prices`].push(
                formatIDRPrice(parseInt(initialValue?.[index]?.harga) || 0)
              );
            }
          });
          addForm.setFieldsValue(initialFieldsValue);
        }, [initialValue]);
        return (
          <>
            {quantityList.map((item2, index) => {
              return (
                <Row className="flex gap-2 w-full" key={`${item.id}-${index}`}>
                  <Col span={9}>
                    <Form.Item
                      className="m-0"
                      name={[`${item.dataIndex}List`, index]}
                      rules={[
                        {
                          required: true,
                          message: REQUIRED_MESSAGE,
                        },
                      ]}
                    >
                      <SelectWithAdd
                        placeholder={`${item.title} ${index + 1}`}
                        className="select-fit-content"
                        options={item.editSelectItems}
                        onSelect={(val) => {
                          if (typeof val === "number") {
                            const selectedPerawatan = item.editSelectItems.find(
                              (item3) => item3.value === val
                            );
                            const prevData = addForm.getFieldsValue();
                            const newData = {
                              [`${item.dataIndex}Prices`]:
                                prevData[`${item.dataIndex}Prices`],
                            };
                            newData[`${item.dataIndex}Prices`][index] =
                              formatIDRPrice(selectedPerawatan.harga);
                            addForm.setFieldsValue(newData);
                          }
                        }}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={5}>
                    <Form.Item
                      className="m-0"
                      name={[`${item.dataIndex}Quantities`, index]}
                      rules={[
                        {
                          required: true,
                          message: REQUIRED_MESSAGE,
                        },
                        {
                          validator: (_, value) => {
                            if (Number(value) <= 0) {
                              return Promise.reject(
                                "Jumlah harus lebih dari nol"
                              );
                            } else {
                              return Promise.resolve();
                            }
                          },
                        },
                      ]}
                    >
                      <Input
                        onChange={(e) => {
                          const quantity = e.target.value.replace(
                            /[^0-9]/g,
                            ""
                          );

                          const prevData = addForm.getFieldsValue();
                          const newData = {
                            [`${item.dataIndex}Quantities`]:
                              prevData[`${item.dataIndex}Quantities`],
                          };
                          newData[`${item.dataIndex}Quantities`][index] =
                            parseInt(quantity);
                          addForm.setFieldsValue(newData);
                        }}
                        placeholder={`Jumlah`}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={5}>
                    <Form.Item
                      className="m-0"
                      name={[`${item.dataIndex}Prices`, index]}
                      rules={[
                        {
                          required: true,
                          message: REQUIRED_MESSAGE,
                        },
                        {
                          validator: (_, value) => {
                            if (
                              parseInt(value?.replace(/[^0-9]/g, "") || 0) <= 0
                            ) {
                              return Promise.reject(
                                "Harga harus lebih dari nol"
                              );
                            } else {
                              return Promise.resolve();
                            }
                          },
                        },
                      ]}
                    >
                      <Input
                        onChange={(e) => {
                          const price = e.target.value.replace(/[^0-9]/g, "");

                          const prevData = addForm.getFieldsValue();
                          const newData = {
                            [`${item.dataIndex}Prices`]:
                              prevData[`${item.dataIndex}Prices`],
                          };
                          newData[`${item.dataIndex}Prices`][index] =
                            price === ""
                              ? ""
                              : formatIDRPrice(parseInt(price) || 0);
                          addForm.setFieldsValue(newData);
                        }}
                        placeholder={`Harga satuan`}
                      />
                    </Form.Item>
                  </Col>

                  <Col span={3}>
                    <Tooltip title="Hapus">
                      <Button
                        onClick={() =>
                          handleRemoveField(item.dataIndex as string, index, [
                            "List",
                            "Prices",
                            "Quantities",
                          ])
                        }
                        danger
                      >
                        <BiTrash size={20} />
                      </Button>
                    </Tooltip>
                  </Col>
                </Row>
              );
            })}
          </>
        );
      },
    },
    {
      title: "Dokumen Pendukung",
      dataIndex: "dokumen_pendukung",
      editable: true,
      className: "min-w-32",
      withQuantity: true,
      hidden: true,
      CustomComponentContentRender: ({ record }) => {
        const dataList = record["dokumen_pendukung"];

        return (
          <>
            {dataList.map((item, index) => (
              <li key={index}>
                <Link
                  to={`${process.env.PUBLIC_URL}/api/download/index.php?fileId=${item.timestamp}`}
                  target="_blank"
                  download
                >
                  <p>{item.nama}</p>
                </Link>
              </li>
            ))}
          </>
        );
      },
      CustomComponentEditorRender: ({
        item,
        handleRemoveField,
        addForm,
        quantityList,
        initialValue,
      }) => {
        const [initialFileList, setInitialFileList] = useState<TypeFile[]>(
          initialValue || []
        );
        const [mappedFileList, setMappedFileList] = useState(
          quantityList.map(() => [])
        );
        return (
          <>
            {initialFileList.map((item2, index) => {
              return (
                <Row className="flex gap-2 w-full items-center" key={index}>
                  <Col span={19} className="hidden">
                    <Form.Item
                      className="m-0"
                      name={[`${item.dataIndex}InitialId`, index]}
                      initialValue={item2.id}
                    >
                      <Input placeholder={`Id`} disabled />
                    </Form.Item>
                  </Col>

                  <Col span={19}>
                    <Form.Item
                      className="m-0"
                      name={[`${item.dataIndex}Initial`, index]}
                      initialValue={item2.nama}
                    >
                      <Input placeholder={`Judul`} disabled />
                    </Form.Item>
                  </Col>

                  <Col span={3}>
                    <Tooltip title="Hapus">
                      <Button
                        onClick={() => {
                          setInitialFileList((prevState) => {
                            const newState = [...prevState];
                            newState.splice(index, 1);

                            const prevData = addForm.getFieldsValue();
                            const newData = {};

                            // delete data from form fields
                            const suffixList = ["Initial", "InitialId"];
                            const prevDataFields = [];
                            generateArray(prevState.length).forEach(
                              (item2, index2) => {
                                suffixList.forEach((suffix) => {
                                  prevDataFields.push(
                                    `${item.dataIndex}${suffix}[${index2}]`
                                  );
                                });
                              }
                            );
                            suffixList.forEach((suffix) => {
                              newData[`${item.dataIndex}${suffix}`] = [];
                            });

                            generateArray(newState.length).forEach(
                              (item2, index2) => {
                                if (index2 < index) {
                                  suffixList.forEach((suffix) => {
                                    newData[`${item.dataIndex}${suffix}`].push(
                                      prevData[`${item.dataIndex}${suffix}`][
                                        index2
                                      ]
                                    );
                                  });
                                } else {
                                  suffixList.forEach((suffix) => {
                                    newData[`${item.dataIndex}${suffix}`].push(
                                      prevData[`${item.dataIndex}${suffix}`][
                                        index2 + 1
                                      ]
                                    );
                                  });
                                }
                              }
                            );

                            addForm.resetFields(prevDataFields);

                            const newFieldsValue = {};
                            suffixList.forEach((suffix) => {
                              newFieldsValue[`${item.dataIndex}${suffix}`] =
                                newData[`${item.dataIndex}${suffix}`];
                            });
                            addForm.setFieldsValue(newFieldsValue);
                            return newState;
                          });
                        }}
                        danger
                      >
                        <BiTrash size={20} />
                      </Button>
                    </Tooltip>
                  </Col>
                </Row>
              );
            })}
            {quantityList.map((item2, index) => (
              <Row className="flex gap-2 w-full items-center" key={index}>
                <Col span={9}>
                  <Form.Item
                    className="m-0"
                    name={[`${item.dataIndex}List`, index]}
                    rules={[
                      {
                        required: true,
                        message: REQUIRED_MESSAGE,
                      },
                    ]}
                  >
                    <Upload
                      beforeUpload={() => false}
                      onChange={(info) => {
                        setMappedFileList((prevState) => {
                          const newState = [...prevState];
                          newState[index] = info.fileList;
                          return newState;
                        });
                      }}
                      fileList={mappedFileList[index]}
                      className="w-full m-0-children"
                    >
                      {!mappedFileList[index]?.length ? (
                        <Button className="w-full flex items-center justify-center gap-2">
                          <BiUpload /> Pilih File
                        </Button>
                      ) : null}
                    </Upload>
                  </Form.Item>
                </Col>
                <Col span={10}>
                  <Form.Item
                    className="m-0"
                    name={[`${item.dataIndex}Titles`, index]}
                  >
                    <Input placeholder={`Judul`} />
                  </Form.Item>
                </Col>

                <Col span={3}>
                  <Tooltip title="Hapus">
                    <Button
                      onClick={() => {
                        setMappedFileList((prevState) => {
                          const newState = [...prevState];
                          quantityList.forEach((item3, index3) => {
                            if (index3 >= index) {
                              newState[index3] = newState[index3 + 1] || null;
                            }
                          });
                          return newState;
                        });
                        handleRemoveField(item.dataIndex as string, index, [
                          "List",
                          "Titles",
                        ]);
                      }}
                      danger
                    >
                      <BiTrash size={20} />
                    </Button>
                  </Tooltip>
                </Col>
              </Row>
            ))}
          </>
        );
      },
    },
    {
      title: "Klinik",
      dataIndex: "ruang",
      editable: true,
      editSelectItems: allRuangList.map((item) => ({
        label: item.nama,
        value: item.id,
      })),
      width: SIZE_COLUMN_XL,
      editIndex: "id_ruang",
    },
    {
      title: "Keterangan",
      dataIndex: "keterangan",
      editable: true,
      className: "min-w-32",
      optional: true,
      hidden: true,
    },
    {
      title: "Aksi",
      align: "center",
      render: (text: string, item: TypeRekamMedis) => (
        <div className="flex gap-3 items-center justify-center">
          <Tooltip title="Periksa">
            <Button
              type="primary"
              className="flex items-center justify-center p-1"
              onClick={() => setPreviewItem(item)}
            >
              <HiMagnifyingGlass size={20} />
            </Button>
          </Tooltip>
          <Tooltip title="Print Nota">
            <Button
              type="primary"
              className="flex items-center justify-center p-1"
              onClick={(e) => {
                e.preventDefault();
                printRekamMedis(item);
              }}
            >
              <BiPrinter size={20} />
            </Button>
          </Tooltip>
          <Tooltip title="Hapus">
            <Button
              type="primary"
              className="flex items-center justify-center p-1"
              onClick={(e) => {
                e.preventDefault();
                const formattedDate = dayjs(item.tgl_periksa).format(
                  DATE_FORMAT
                );
                confirm({
                  title: `Apakah Anda yakin ingin menghapus data pada tanggal : ${formattedDate}?`,
                  icon: null,
                  content:
                    "Data yang sudah dihapus tidak dapat dikembalikan lagi.",
                  okButtonProps: { type: "primary", danger: true },
                  okText: "Hapus",
                  cancelButtonProps: { type: "primary" },
                  cancelText: "Batal",
                  onOk() {
                    deleteData(item.id);
                  },
                  maskClosable: true,
                });
              }}
              danger
            >
              <BiTrash size={20} />
            </Button>
          </Tooltip>
        </div>
      ),
      width: SIZE_COLUMN_LG,
    },
  ];

  const addData = async (
    data: TypeRekamMedis & {
      perawatanList: (number | string)[];
      perawatanQuantities: number[];
      perawatanPrices: string[];
      dokumen_pendukungList: { file: File; fileList: any[] }[];
      dokumen_pendukungTitles: string[];
    }
  ) => {
    // handle data perawatan
    const perawatan =
      data.perawatanList?.map?.((item, index) => ({
        id_perawatan: item,
        jumlah: data.perawatanQuantities[index],
        harga: parseInt(data.perawatanPrices[index].replace(/[^0-9]/g, "")),
      })) || [];
    let newData: any = { ...data, perawatan };
    delete newData.perawatanList;
    delete newData.perawatanQuantities;
    delete newData.perawatanPrices;

    // handle data dokumen_pendukung
    const dokumen_pendukung =
      data.dokumen_pendukungList?.map?.((item) => item.file) || [];
    const dokumen_pendukung_title = data.dokumen_pendukungTitles;
    newData = { ...newData, dokumen_pendukung, dokumen_pendukung_title };
    delete newData.dokumen_pendukungList;
    delete newData.dokumen_pendukungTitles;

    newData.no_rm = currentPasien.no_rm;

    const res = await addRekamMedis(newData);
    if (res?.data) {
      getData();
    }
    return res;
  };

  const editDataRekamMedis = async (
    data: TypeRekamMedis & {
      perawatanList: (number | string)[];
      perawatanQuantities: number[];
      perawatanPrices: string[];
      dokumen_pendukungList: { file: File; fileList: any[] }[];
      dokumen_pendukungTitles: string[];
      dokumen_pendukungInitial: string[];
      dokumen_pendukungInitialId: number[];
    }
  ) => {
    // handle data perawatan
    const perawatan =
      data.perawatanList?.map?.((item, index) => ({
        id_perawatan: item,
        jumlah: data.perawatanQuantities[index],
        harga: parseInt(data.perawatanPrices[index].replace(/[^0-9]/g, "")),
      })) || [];
    let newData: any = { ...data, perawatan };
    delete newData.perawatanList;
    delete newData.perawatanQuantities;
    delete newData.perawatanPrices;

    // handle data dokumen_pendukung
    const dokumen_pendukung =
      data.dokumen_pendukungList?.map?.((item) => item.file) || [];
    const dokumen_pendukung_title = data.dokumen_pendukungTitles;
    newData = { ...newData, dokumen_pendukung, dokumen_pendukung_title };
    delete newData.dokumen_pendukungList;
    delete newData.dokumen_pendukungTitles;

    // handle kept data dokumen_pendukung
    const dokumen_pendukung_keep = data.dokumen_pendukungInitialId || [];
    newData = { ...newData, dokumen_pendukung_keep };
    delete newData.dokumen_pendukungInitial;
    delete newData.dokumen_pendukungInitialId;

    newData.no_rm = currentPasien.no_rm;

    const res = await editRekamMedis({ ...newData, id: previewItem.id });
    return res;
  };

  const deleteData = async (id: number) => {
    const messageKey = new Date().toISOString();
    showLoadingMessage(messageKey);
    const res = await deleteRekamMedis(id);
    if (res?.success) {
      showSuccessMessage(messageKey, "Data berhasil dihapus");
    } else {
      showErrorMessage(
        messageKey,
        res.displayMessage || FAILED_DISPLAY_MESSAGE
      );
    }
  };

  const [modalOpen, setModalOpen] = useState(false);
  const [previewItem, setPreviewItem] = useState<TypeRekamMedis>(null);
  const [editForm] = useForm();
  const [isEditing, setIsEditing] = useState(false);

  const DescriptionItem = useMemo(
    () =>
      infoList.map((item, index) => (
        <Descriptions.Item
          key={index}
          label={item.title as string}
          labelStyle={{ width: "30%" }}
          span={3}
        >
          {isEditing && item.editable ? (
            <EditorComponent
              text={currentPasien?.[item.dataIndex as string]}
              column={item}
              dataForm={editForm}
            />
          ) : (
            <ContentComponent
              text={currentPasien?.[item.dataIndex as string] || item.value}
              record={currentPasien}
              column={item}
            />
            // <p className="whitespace-pre-wrap">{item.value}</p>
          )}
        </Descriptions.Item>
      )),
    [isEditing, currentPasien, editForm]
  );
  const editData = async (data: Partial<TypePasien>) => {
    data.tgl_lahir = formatToNoonISOString(data.tgl_lahir);
    return await editPasien({ ...data, id: currentPasien.id });
  };
  const [loadingDownload, setLoadingDownload] = useState(false);

  // Odontogram
  const { allGigiList, getGigiByNoRM } = useContext(GigiContext);

  return (
    <div className="w-full flex flex-col p-6 gap-6 items-center">
      <div className="w-full rounded-lg bg-white overflow-hidden flex p-4 flex-col gap-4 shadow-md transition-all">
        <div className="flex gap-3 justify-start items-center">
          <Tooltip title="Kembali">
            <Link
              to={urlRef || "/pasien"}
              className="flex justify-center items-center"
            >
              <BiLeftArrowCircle size={25} />
            </Link>
          </Tooltip>

          <h3>Detail Pasien</h3>
        </div>
        {isLoadingGetPasien ? (
          <div
            role="status"
            className="w-full p-12 flex items-center justify-center"
          >
            <svg
              aria-hidden="true"
              className="w-16 h-16 mr-2 text-white animate-spin fill-blue-600"
              viewBox="0 0 100 101"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                fill="currentColor"
              />
              <path
                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                fill="currentFill"
              />
            </svg>
          </div>
        ) : (
          <>
            <div className="w-full flex justify-end items-center gap-4">
              {isEditing ? (
                <>
                  <Button
                    className="flex items-center gap-2"
                    onClick={() => setIsEditing(false)}
                  >
                    Batalkan
                  </Button>
                  <Button
                    type="primary"
                    className="flex items-center gap-2"
                    onClick={() => editForm.submit()}
                  >
                    <BiSave size={20} />
                    Simpan Perubahan
                  </Button>
                </>
              ) : (
                <Button
                  type="primary"
                  className="flex items-center gap-2"
                  onClick={() => setIsEditing(true)}
                >
                  <BiEdit size={20} />
                  Edit Data Pasien
                </Button>
              )}
            </div>
            <Form
              form={editForm}
              onFinish={async (values) => {
                const messageKey = new Date().toISOString();
                showLoadingMessage(messageKey);
                const res = await editData(values);
                if (res.success) {
                  showSuccessMessage(messageKey, "Data berhasil diubah");
                  setIsEditing(false);
                } else {
                  showErrorMessage(
                    messageKey,
                    res.displayMessage || FAILED_DISPLAY_MESSAGE
                  );
                }
              }}
            >
              <Descriptions className="w-full" column={3} bordered>
                {DescriptionItem}
              </Descriptions>
            </Form>
          </>
        )}
      </div>
      <div className="w-full rounded-lg bg-white overflow-hidden flex p-4 pb-14 flex-col gap-4 shadow-md transition-all">
        <h3>Odontogram</h3>
        <Odontogram gigiList={allGigiList} currentPasien={currentPasien} />
        <p className="mt-3">Keterangan :</p>
        <div className="grid grid-cols-2 md:grid-cols-3 gap-y-8 mt-14">
          <div className="flex gap-2 items-center px-5 row-span-2">
            <Tooth gigi={{ no_gigi: 14, une: true }} />
            <p>Uneven (Tidak Rata)</p>
          </div>
          <div className="flex gap-2 items-center px-5 row-span-2">
            <Tooth gigi={{ no_gigi: 14, pre: true }} />
            <p>Pre-existing (Kondisi yang Sudah Ada Sebelumnya)</p>
          </div>
          <div className="flex gap-2 items-center px-5 row-span-2">
            <Tooth gigi={{ no_gigi: 14, ano: true }} />
            <p>Anomaly (Anomali)</p>
          </div>
          <div className="flex gap-2 items-center px-5">
            <Tooth gigi={{ no_gigi: 14, fractured: true }} />
            <p>Fractured (Gigi Patah)</p>
          </div>
          <div className="flex gap-2 items-center px-5">
            <Tooth gigi={{ no_gigi: 14, mahkotaLogam: true }} />
            <p>Mahkota Logam (Logam Crown)</p>
          </div>
          <div className="flex gap-2 items-center px-5">
            <Tooth gigi={{ no_gigi: 14, mahkotaNonLogam: true }} />
            <p>Mahkota Non-Logam (Non-Metal Crown)</p>
          </div>
          <div className="flex gap-2 items-center px-5">
            <Tooth gigi={{ no_gigi: 14, sisaAkar: true }} />
            <p>Sisa Akar (Root Remnant)</p>
          </div>
          <div className="flex gap-2 items-center px-5">
            <Tooth gigi={{ no_gigi: 14, gigiHilang: true }} />
            <p>Gigi Hilang (Missing Tooth)</p>
          </div>
          <div className="flex gap-2 items-center px-5">
            <Tooth gigi={{ no_gigi: 14, nonVital: true }} />
            <p>Non-Vital (Gigi Non-Vital)</p>
          </div>
          <div className="flex gap-2 items-center px-5">
            <Tooth gigi={{ no_gigi: 14, perawatanSalAkar: true }} />
            <p>Perawatan Saluran Akar (Root Canal Treatment)</p>
          </div>
          <div className="flex gap-2 items-center px-5">
            <Tooth gigi={{ no_gigi: 14, oklusal: { caries: true } }} />
            <p>Karies (Caries)</p>
          </div>
          <div className="flex gap-2 items-center px-5">
            <Tooth gigi={{ no_gigi: 14, oklusal: { tambalanLogam: true } }} />
            <p>Tambalan Logam</p>
          </div>
          <div className="flex gap-2 items-center px-5">
            <Tooth
              gigi={{ no_gigi: 14, oklusal: { tambalanNonLogam: true } }}
            />
            <p>Tambalan Non Logam</p>
          </div>
          <div className="flex gap-2 items-center px-5">
            <Tooth gigi={{ no_gigi: 14, lainnya: true }} />
            <p>Lainnya (ditulis di keterangan)</p>
          </div>
          <div className="flex gap-2 items-center px-5">
            <div className="flex gap-0 items-center">
              <Tooth gigi={{ no_gigi: 14, jembatan: "kiri" }} />
              <Tooth gigi={{ no_gigi: 14, jembatan: "tengah" }} />
              <Tooth gigi={{ no_gigi: 14, jembatan: "kanan" }} />
            </div>
            <p>Jembatan</p>
          </div>
          <div className="flex gap-2 items-center px-5 col-span-2">
            <div className="flex gap-0 items-center">
              <Tooth gigi={{ no_gigi: 14, migrasi: "kiri" }} />
              <Tooth gigi={{ no_gigi: 14, migrasi: "kanan" }} />
              <Tooth gigi={{ no_gigi: 14, migrasi: "atas" }} />
              <Tooth gigi={{ no_gigi: 14, migrasi: "bawah" }} />
            </div>
            <p>Migrasi/Version/Rotasi</p>
          </div>
        </div>
      </div>
      <div className="w-full rounded-lg bg-white overflow-hidden flex p-4 flex-col gap-4 shadow-md transition-all">
        <h3>Rekam Medis</h3>
        <div className="w-full flex justify-end items-center gap-4">
          <Button
            type="primary"
            className="flex items-center gap-2"
            onClick={async () => {
              setLoadingDownload(true);
              const res = await getAllRekamMedisByNoRM({ no_rm });
              if (res.success) {
                downloadPDF(currentPasien, res.data);
              } else {
                showErrorMessage(
                  new Date().toISOString(),
                  res?.displayMessage || FAILED_DISPLAY_MESSAGE
                );
              }
              setLoadingDownload(false);
            }}
            loading={loadingDownload}
          >
            <BiDownload size={20} />
            Unduh PDF
          </Button>
          <Button
            type="primary"
            className="flex items-center gap-2"
            onClick={() => setModalOpen(true)}
          >
            <FaFileMedical size={20} />
            Tambah Rekam Medis
          </Button>
        </div>
        ;
        <CustomTable<TypeRekamMedis>
          pagination={pagination}
          dataList={rekamMedisList.map((item) => {
            return { ...item };
          })}
          columns={rekamMedisColumns.filter((item) => !item.hidden)}
          isLoading={isLoadingGet}
          setFilter={setFilter}
          filter={filter}
          editingItem={null}
          setEditingItem={null}
          editData={null}
        />
      </div>
      <AddDataModal<TypeRekamMedis>
        title="Tambah Rekam Medis"
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        columns={rekamMedisColumns.filter((item) => item.editable)}
        addData={addData}
      />
      <PreviewModal<TypeRekamMedis>
        title="Detail Rekam Medis"
        previewItem={previewItem}
        setPreviewItem={setPreviewItem}
        columns={rekamMedisColumns.filter(
          (item) => !(item.title === "Aksi") && !(item.title === "No")
        )}
        editData={editDataRekamMedis}
      />
    </div>
  );
};

export default DetailPasien;

// const dummyData = [
//   {
//     no_gigi: 14,
//     une: true,
//     fractured: true,
//   },
//   {
//     no_gigi: 82,
//     une: true,
//     distal: {
//       caries: true,
//     },
//     mesial: {
//       tambalanLogam: true,
//     },
//   },
//   {
//     no_gigi: 52,
//     une: true,
//     distal: {
//       caries: true,
//     },
//     mesial: {
//       tambalanNonLogam: true,
//     },
//   },
//   {
//     no_gigi: 15,
//     sisaAkar: true,
//     gigiHilang: true,
//   },
//   {
//     no_gigi: 16,
//     gigiHilang: true,
//   },
//   {
//     no_gigi: 23,
//     mahkotaNonLogam: true,
//   },
//   {
//     no_gigi: 73,
//     mahkotaLogam: true,
//   },
//   {
//     no_gigi: 26,
//     ano: true,
//     nonVital: true,
//   },
//   {
//     no_gigi: 47,
//     jembatan: "kiri",
//   },
//   {
//     no_gigi: 46,
//     jembatan: "tengah",
//   },
//   {
//     no_gigi: 45,
//     jembatan: "kanan",
//   },
//   {
//     no_gigi: 13,
//     jembatan: "kiri",
//   },
//   {
//     no_gigi: 12,
//     une: true,
//     lainnya: true,
//     jembatan: "tengah",
//   },
//   {
//     no_gigi: 11,
//     jembatan: "kanan",
//   },
//   {
//     no_gigi: 34,
//     perawatanSalAkar: true,
//   },
//   {
//     no_gigi: 32,
//     migrasi: "kanan",
//   },
//   {
//     no_gigi: 36,
//     migrasi: "atas",
//   },
//   {
//     no_gigi: 63,
//     migrasi: "bawah",
//   },
//   {
//     no_gigi: 64,
//     migrasi: "kiri",
//   },
// ];
