import { TypeGigi } from "../utils/types";

const ToothContainer: React.FC<{
  children: React.ReactNode;
  gigi: TypeGigi;
  kuadran: number;
  setSelectedGigi?: React.Dispatch<React.SetStateAction<TypeGigi>>;
  style?: any;
}> = ({ children, gigi, kuadran, setSelectedGigi, style }) => {
  const AdditionalItem: React.FC = () => {
    return (
      <p
        className={`${kuadran === 1 || kuadran === 2 ? "mb-3" : "mt-3"} ${
          setSelectedGigi ? "group-hover:bg-gray-300" : ""
        } w-full rounded-xl p-1 text-center transition-all`}
      >
        {gigi.no_gigi}
      </p>
    );
  };
  return (
    <div
      onClick={() => setSelectedGigi?.(gigi)}
      className={`${
        setSelectedGigi ? "cursor-pointer" : ""
      } flex flex-col relative gap-0 justify-end items-center group transition-all rounded-lg`}
      style={style}
    >
      {kuadran === 1 || kuadran === 2 ? <AdditionalItem /> : null}
      {children}
      {kuadran === 1 || kuadran === 2 ? null : <AdditionalItem />}
    </div>
  );
};

export default ToothContainer;
