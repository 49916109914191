import { useEffect, PropsWithChildren, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import DashboardLayout from "./DashboardLayout";
import { GlobalContext } from "../contexts/GlobalState";

const PrivateRoute: React.FC<PropsWithChildren> = ({ children }) => {
  const navigate = useNavigate();
  const { user, getUserData } = useContext(GlobalContext);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    (async () => {
      const res = await getUserData();
      if (!res.data) {
        navigate("/login");
      }
      setLoading(false);
    })();
  }, []);

  return loading || !user?.id ? (
    <></>
  ) : (
    <DashboardLayout>{children}</DashboardLayout>
  );
};

export default PrivateRoute;
