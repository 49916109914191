import { useState, useContext, useEffect } from "react";
import { BsFillPersonPlusFill } from "react-icons/bs";
import { HiMagnifyingGlass } from "react-icons/hi2";
import { Button, Input, Tooltip, Modal } from "antd";
import { debounce } from "lodash";
import { PasienContext } from "../contexts/PasienState";
import { TypePasien, TypeFilterTable, TypeTableColumn } from "../utils/types";
import CustomTable from "../components/CustomTable";
import { BiTrash } from "react-icons/bi";
import { GlobalContext } from "../contexts/GlobalState";
import {
  FAILED_DISPLAY_MESSAGE,
  GENDER_OPTIONS,
  SIZE_COLUMN_LG,
  SIZE_COLUMN_MD,
  SIZE_COLUMN_SM,
  SIZE_COLUMN_XL,
  SIZE_COLUMN_XS,
} from "../utils/constants.tsx";
import AddDataModal from "../components/AddDataModal";
import { formatToNoonISOString, isoStringToAge } from "../utils/functions";
import { useNavigate } from "react-router-dom";

const { Search } = Input;
const { confirm } = Modal;

const Pasien: React.FC = () => {
  const navigate = useNavigate();
  const [componentDidMount, setComponentDidMount] = useState(false);
  const { showLoadingMessage, showSuccessMessage, showErrorMessage } =
    useContext(GlobalContext);
  const {
    pasienList,
    pagination,
    isLoadingGet,
    getPasien,
    addPasien,
    editPasien,
    deletePasien,
  } = useContext(PasienContext);
  const [filter, setFilter] = useState<TypeFilterTable<TypePasien>>({
    page: 1,
    sortField: "nama",
    isAsc: true,
  });
  const [searchQuery, setSearchQuery] = useState("");
  const getData = (page?: number) => {
    getPasien({
      page: page || filter.page,
      query: searchQuery,
      sort_field: filter.sortField,
      is_asc: filter.isAsc,
    });
  };
  const getDataDebounce = debounce((e) => {
    setSearchQuery(e?.target?.value);
  }, 500);

  // Perform get data
  useEffect(() => {
    getData();
    setComponentDidMount(true);
  }, []);

  useEffect(() => {
    if (componentDidMount) {
      getData();
    }
  }, [filter]);

  useEffect(() => {
    if (componentDidMount) {
      getData(1);
    }
  }, [searchQuery]);

  const [editingItem, setEditingItem] = useState<TypePasien>(null);

  const pasienColumns: TypeTableColumn<TypePasien>[] = [
    {
      title: "No",
      dataIndex: "no",
      align: "center",
      width: SIZE_COLUMN_XS,
    },
    {
      title: "No RM",
      dataIndex: "no_rm",
      sorter: true,
      width: SIZE_COLUMN_MD,
    },
    {
      title: "Nama",
      dataIndex: "nama",
      sorter: true,
      editable: true,
      width: SIZE_COLUMN_XL,
    },
    {
      title: "Tgl Lahir",
      dataIndex: "tgl_lahir",
      sorter: true,
      editable: true,
      width: SIZE_COLUMN_XL,
    },
    {
      title: "Umur",
      dataIndex: "umur",
      width: SIZE_COLUMN_SM,
    },
    {
      title: "Jenis Kelamin",
      dataIndex: "jenis_kelamin",
      sorter: true,
      editable: true,
      width: SIZE_COLUMN_LG,
      columnOptions: GENDER_OPTIONS,
    },
    {
      title: "Alamat",
      dataIndex: "alamat",
      editable: true,
      className: "min-w-32",
    },
    {
      title: "No HP",
      dataIndex: "no_hp",
      editable: true,
      width: SIZE_COLUMN_XL,
    },
    {
      title: "Riwayat Penyakit",
      dataIndex: "riwayat_penyakit",
      editable: true,
      width: SIZE_COLUMN_XL,
      hidden: true,
      optional: true,
    },
    {
      title: "Riwayat Alergi Obat",
      dataIndex: "riwayat_alergi",
      editable: true,
      width: SIZE_COLUMN_XL,
      hidden: true,
      optional: true,
    },
    {
      title: "Aksi",
      align: "center",
      render: (text: string, item: TypePasien) => (
        <div className="flex gap-3 items-center justify-center">
          <Tooltip title="Periksa">
            <Button
              type="primary"
              className="flex items-center justify-center p-1"
              onClick={() => navigate(`/pasien/${item.no_rm}`)}
            >
              <HiMagnifyingGlass size={20} />
            </Button>
          </Tooltip>
          {/* <Tooltip title="Edit">
            <Button
              type="primary"
              className="flex items-center justify-center p-1"
              onClick={() => setEditingItem(item)}
            >
              <BiEdit size={20} />
            </Button>
          </Tooltip> */}
          <Tooltip title="Hapus">
            <Button
              type="primary"
              className="flex items-center justify-center p-1"
              onClick={(e) => {
                e.preventDefault();
                confirm({
                  title: `Apakah Anda yakin ingin menghapus pasien : ${item.nama}?`,
                  icon: null,
                  content:
                    "Data yang sudah dihapus tidak dapat dikembalikan lagi.",
                  okButtonProps: { type: "primary", danger: true },
                  okText: "Hapus",
                  cancelButtonProps: { type: "primary" },
                  cancelText: "Batal",
                  onOk() {
                    deleteData(item.id);
                  },
                  maskClosable: true,
                });
              }}
              danger
            >
              <BiTrash size={20} />
            </Button>
          </Tooltip>
        </div>
      ),
      width: SIZE_COLUMN_LG,
    },
  ];

  const addData = async (data: TypePasien) => {
    data.tgl_lahir = formatToNoonISOString(data.tgl_lahir);
    const res = await addPasien(data);
    if (res?.data) {
      getData();
    }
    return res;
  };
  const editData = async (data: Partial<TypePasien>) => {
    data.tgl_lahir = formatToNoonISOString(data.tgl_lahir);
    return await editPasien({ ...data, id: editingItem.id });
  };
  const deleteData = async (id: number) => {
    const messageKey = new Date().toISOString();
    showLoadingMessage(messageKey);
    const res = await deletePasien(id);
    if (res?.success) {
      showSuccessMessage(messageKey, "Data berhasil dihapus");
    } else {
      showErrorMessage(
        messageKey,
        res.displayMessage || FAILED_DISPLAY_MESSAGE
      );
    }
  };

  const [modalOpen, setModalOpen] = useState(false);
  return (
    <div className="w-full flex flex-col p-6 gap-6 items-center">
      <div className="w-full rounded-lg bg-white overflow-hidden flex p-4 flex-col gap-4 shadow-md transition-all">
        <h3>Data Pasien</h3>
        <div className="w-full flex justify-between items-center gap-4">
          <div className="flex gap-4 items-center">
            <Search
              placeholder="Cari nama, alamat, dll"
              onChange={getDataDebounce}
              className="w-auto md:w-96"
            />
          </div>
          <Button
            type="primary"
            className="flex items-center gap-2"
            onClick={() => setModalOpen(true)}
          >
            <BsFillPersonPlusFill size={20} />
            Tambah Pasien
          </Button>
        </div>
        <CustomTable<TypePasien>
          pagination={pagination}
          dataList={pasienList.map((item) => {
            const umur = isoStringToAge(item.tgl_lahir);
            return { ...item, umur };
          })}
          columns={pasienColumns.filter((item) => !item.hidden)}
          isLoading={isLoadingGet}
          setFilter={setFilter}
          filter={filter}
          editingItem={editingItem}
          setEditingItem={setEditingItem}
          editData={editData}
        />
      </div>
      <AddDataModal<TypePasien>
        title="Tambah Pasien"
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        columns={pasienColumns.filter((item) => item.editable)}
        addData={addData}
      />
    </div>
  );
};

export default Pasien;
