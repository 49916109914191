import { Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import PrivateRoute from "./components/PrivateRoute";
import Login from "./pages/Login";
import Dokter from "./pages/Dokter";
import Providers from "./components/Providers";
import Pasien from "./pages/Pasien";
import Ruang from "./pages/Ruang";
import Perawatan from "./pages/Perawatan";
import DetailPasien from "./pages/pasien/DetailPasien";
import User from "./pages/User";
import Laporan from "./pages/Laporan";

function App() {
  return (
    <Providers>
      <Routes>
        <Route path="/login" Component={Login} />
        <Route path="*" Component={null} />
      </Routes>
      <PrivateRoute>
        <Routes>
          <Route path="/" Component={Home} />
          <Route path="/dokter" Component={Dokter} />
          <Route path="/pasien" Component={Pasien} />
          <Route path="/pasien/:no_rm" Component={DetailPasien} />
          <Route path="/ruang" Component={Ruang} />
          <Route path="/perawatan" Component={Perawatan} />
          <Route path="/admin" Component={User} />
          <Route path="/laporan" Component={Laporan} />
          <Route path="*" Component={null} />
        </Routes>
      </PrivateRoute>
    </Providers>
  );
}

export default App;
