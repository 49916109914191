import { useContext, useState, useEffect } from "react";
import { useForm } from "antd/es/form/Form";
import { debounce } from "lodash";
import { GlobalContext } from "../contexts/GlobalState";
import { Button, Form, Input, Modal, Tooltip } from "antd";
import {
  FAILED_DISPLAY_MESSAGE,
  GENDER_OPTIONS,
  REQUIRED_MESSAGE,
  SIZE_COLUMN_LG,
  SIZE_COLUMN_MD,
  SIZE_COLUMN_SM,
  SIZE_COLUMN_XL,
  SIZE_COLUMN_XS,
} from "../utils/constants.tsx";
import { isoStringToAge } from "../utils/functions";
import CustomTable from "./CustomTable";
import { TypeFilterTable, TypePasien, TypeTableColumn } from "../utils/types";
import { PasienContext } from "../contexts/PasienState";

interface TypeAddModal {
  modalOpen: boolean;
  currentRuang: string;
  addData: (values: any) => Promise<any>;
  setModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
}
const AddAntreanModal: React.FC<TypeAddModal> = ({
  modalOpen,
  currentRuang,
  addData,
  setModalOpen,
}) => {
  const { showLoadingMessage, showSuccessMessage, showErrorMessage } =
    useContext(GlobalContext);

  const [addForm] = useForm();
  const onSubmitForm = async (values) => {
    const messageKey = new Date().toISOString();
    showLoadingMessage(messageKey);
    const data = {
      id_ruang: parseInt(currentRuang),
      no_rm: selectedPasien.no_rm,
      keterangan: values.keterangan,
    };
    const res = await addData(data);
    if (res?.success) {
      showSuccessMessage(messageKey, "Data berhasil ditambahkan");
      setModalOpen(false);
      addForm.resetFields();
    } else {
      showErrorMessage(
        messageKey,
        res?.displayMessage || FAILED_DISPLAY_MESSAGE
      );
    }
  };

  // Pasien functions
  const { pasienList, pagination, isLoadingGet, getPasien } =
    useContext(PasienContext);

  const [componentDidMount, setComponentDidMount] = useState(false);
  const [filter, setFilter] = useState<TypeFilterTable<TypePasien>>({
    page: 1,
    sortField: "nama",
    isAsc: true,
  });
  const [searchQuery, setSearchQuery] = useState("");
  const getData = (page?: number) => {
    getPasien({
      page: page || filter.page,
      query: searchQuery,
      sort_field: filter.sortField,
      is_asc: filter.isAsc,
    });
  };
  const getDataDebounce = debounce((e) => {
    setSearchQuery(e?.target?.value);
  }, 500);

  // Perform get data
  useEffect(() => {
    getData();
    setComponentDidMount(true);
  }, []);

  useEffect(() => {
    if (componentDidMount) {
      getData();
    }
  }, [filter]);

  useEffect(() => {
    if (componentDidMount) {
      getData(1);
    }
  }, [searchQuery]);

  const pasienColumns: TypeTableColumn<TypePasien>[] = [
    {
      title: "No",
      dataIndex: "no",
      align: "center",
      width: SIZE_COLUMN_XS,
    },
    {
      title: "No RM",
      dataIndex: "no_rm",
      sorter: true,
      width: SIZE_COLUMN_MD,
    },
    {
      title: "Nama",
      dataIndex: "nama",
      sorter: true,
      editable: true,
      width: SIZE_COLUMN_XL,
    },
    {
      title: "Umur",
      dataIndex: "umur",
      width: SIZE_COLUMN_SM,
    },
    {
      title: "Jenis Kelamin",
      dataIndex: "jenis_kelamin",
      sorter: true,
      editable: true,
      width: SIZE_COLUMN_LG,
      columnOptions: GENDER_OPTIONS,
    },
    {
      title: "Alamat",
      dataIndex: "alamat",
      editable: true,
      className: "min-w-32",
    },
    {
      title: "Aksi",
      align: "center",
      render: (text: string, item: TypePasien) => (
        <div className="flex gap-3 items-center justify-center">
          <Tooltip title="Pilih">
            <Button
              type="primary"
              className="flex items-center justify-center py-1 px-3"
              onClick={() => setSelectedPasien(item)}
            >
              Pilih
            </Button>
          </Tooltip>
        </div>
      ),
      width: SIZE_COLUMN_LG,
    },
  ];
  const [selectedPasien, setSelectedPasien] = useState<TypePasien>(null);
  useEffect(() => {
    if (selectedPasien) {
      addForm.setFieldValue("nama_pasien", selectedPasien.nama);
      addForm.validateFields(["nama_pasien"]);
    } else {
      setSearchQuery("");
      addForm.setFieldValue("nama_pasien", "");
    }
  }, [selectedPasien]);
  return (
    <Modal
      title={"Tambah Antrean"}
      open={modalOpen}
      okText="Tambah"
      onOk={() => addForm.submit()}
      onCancel={() => setModalOpen(false)}
      className="max-w-none !w-11/12 md:!w-2/3 !p-4 flex flex-col gap-3"
      centered
    >
      <Form
        form={addForm}
        className="w-full flex p-4 flex-col gap-3"
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        onFinish={onSubmitForm}
      >
        <div className="w-full flex flex-col gap-3">
          <Form.Item
            label={"Pasien"}
            name={"nama_pasien"}
            rules={[
              {
                required: true,
                message: REQUIRED_MESSAGE,
              },
              {
                validator: (_, value) => {
                  if (!selectedPasien) {
                    return Promise.reject("Pasien tidak valid");
                  } else {
                    return Promise.resolve();
                  }
                },
              },
            ]}
            className="m-0 flex flex-col"
          >
            <Input
              placeholder="Cari nama pasien"
              onChange={getDataDebounce}
              styles={{
                input: {
                  background: selectedPasien ? "none" : "",
                  border: selectedPasien ? "none" : "",
                  outline: selectedPasien ? "none" : "",
                  color: selectedPasien ? "black" : "",
                  paddingLeft: selectedPasien ? "0" : "",
                },
              }}
              className="w-full"
              disabled={!!selectedPasien}
              value={selectedPasien?.nama}
            />
          </Form.Item>

          <div
            className="w-full transition-all overflow-hidden"
            style={{
              maxHeight: selectedPasien ? "1000px" : "0px",
              transitionDuration: "1s",
            }}
          >
            <p
              className="cursor-pointer w-max text-biru hover:text-biruMuda transition-all"
              onClick={() => {
                setSelectedPasien(null);
              }}
            >
              Ubah pasien
            </p>
          </div>
          <div
            className="w-full transition-all overflow-hidden"
            style={{
              maxHeight: selectedPasien ? "0px" : "1000px",
              transitionDuration: "1s",
            }}
          >
            <CustomTable<TypePasien>
              pagination={pagination}
              dataList={pasienList.map((item) => {
                const umur = isoStringToAge(item.tgl_lahir);
                return { ...item, umur };
              })}
              columns={pasienColumns.filter((item) => !item.hidden)}
              isLoading={isLoadingGet}
              setFilter={setFilter}
              filter={filter}
              editingItem={null}
              setEditingItem={null}
              editData={null}
            />
          </div>
        </div>
        <div className="w-full flex flex-col gap-3">
          <Form.Item label={"Keterangan"} name={"keterangan"} className="m-0">
            <Input.TextArea className="w-full" autoSize={{ minRows: 3 }} />
          </Form.Item>
        </div>
      </Form>
    </Modal>
  );
};

export default AddAntreanModal;
