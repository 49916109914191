import { PropsWithChildren, createContext, useState } from "react";
import { TypePasien, TypePasienContext } from "../utils/types";
import axios from "axios";
import { FAILED_DATA } from "../utils/constants.tsx";
import { host } from "./config";

axios.defaults.withCredentials = true;
const initialState: TypePasienContext = {
  pasienList: [],
  currentPasien: null,
  pagination: {
    current: 1,
    pageSize: 10,
    total: 0,
  },
  isLoadingGet: false,
};
export const PasienContext = createContext<TypePasienContext>(initialState);
const PasienState: React.FC<PropsWithChildren> = ({ children }) => {
  const [state, setState] = useState<TypePasienContext>(initialState);
  const dispatch = (payload: Partial<TypePasienContext>) => {
    setState((prevState) => ({
      ...prevState,
      ...payload,
    }));
  };

  const getPasien = async (data: {
    page?: number;
    query?: string;
    sort_field?: string;
    is_asc?: boolean;
  }) => {
    dispatch({ isLoadingGet: true });
    try {
      const res = await axios.get(host + "/pasien/get_pasien.php", {
        params: data,
      });
      if (res.data.success) {
        dispatch({
          pasienList: res.data.data || [],
          pagination: res.data.pagination,
        });
      }
      return res.data;
    } catch (err) {
      return FAILED_DATA;
    } finally {
      dispatch({ isLoadingGet: false });
    }
  };

  const getPasienByNoRM = async (noRM: string) => {
    const data = {
      query: noRM,
    };
    dispatch({ isLoadingGet: true });
    try {
      const res = await axios.get(host + "/pasien/get_pasien.php", {
        params: data,
      });
      if (res.data.success && res.data.data.length === 1) {
        dispatch({
          currentPasien: res.data.data[0],
        });
        return res.data;
      } else {
        return FAILED_DATA;
      }
    } catch (err) {
      return FAILED_DATA;
    } finally {
      dispatch({ isLoadingGet: false });
    }
  };

  const addPasien = async (data: TypePasien) => {
    try {
      const res = await axios.post(host + "/pasien/add_pasien.php", data);
      return res.data;
    } catch (err) {
      return FAILED_DATA;
    }
  };

  const editPasien = async (data: Partial<TypePasien>) => {
    try {
      const res = await axios.post(host + "/pasien/edit_pasien.php", data);
      if (res.data.success) {
        dispatch({
          currentPasien: { ...state.currentPasien, ...res.data.data },
        });
      }

      return res.data;
    } catch (err) {
      return FAILED_DATA;
    }
  };

  const deletePasien = async (id: number) => {
    try {
      const res = await axios.post(host + "/pasien/delete_pasien.php", { id });

      const prevIndex = state.pasienList.findIndex((item) => item.id === id);
      if (prevIndex !== -1) {
        const newPasienList = [...state.pasienList];
        newPasienList.splice(prevIndex, 1);
        dispatch({ pasienList: newPasienList });
      }

      return res.data;
    } catch (err) {
      return FAILED_DATA;
    }
  };

  return (
    <PasienContext.Provider
      value={{
        ...state,
        getPasien,
        addPasien,
        editPasien,
        deletePasien,
        getPasienByNoRM,
      }}
    >
      {children}
    </PasienContext.Provider>
  );
};

export default PasienState;
