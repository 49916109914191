import { useState, useContext, useEffect } from "react";
import { Button, Input, Tooltip, Modal } from "antd";
import { debounce } from "lodash";
import { TypeUser, TypeFilterTable, TypeTableColumn } from "../utils/types";
import CustomTable from "../components/CustomTable";
import { BiEdit, BiTrash } from "react-icons/bi";
import { GlobalContext } from "../contexts/GlobalState";
import {
  FAILED_DISPLAY_MESSAGE,
  ROLE_OPTIONS,
  SIZE_COLUMN_LG,
  SIZE_COLUMN_XS,
} from "../utils/constants.tsx";
import AddDataModal from "../components/AddDataModal";
import { MdLockReset } from "react-icons/md";
import { RiAdminFill } from "react-icons/ri";
import ResetPasswordModal from "../components/ResetPasswordModal";

const { Search } = Input;
const { confirm } = Modal;

const User: React.FC = () => {
  const [componentDidMount, setComponentDidMount] = useState(false);
  const {
    user,
    userList,
    pagination,
    isLoadingGet,
    getUser,
    addUser,
    editUser,
    deleteUser,
    showLoadingMessage,
    showSuccessMessage,
    showErrorMessage,
  } = useContext(GlobalContext);
  const [filter, setFilter] = useState<TypeFilterTable<TypeUser>>({
    page: 1,
    sortField: "nama",
    isAsc: true,
  });
  const [searchQuery, setSearchQuery] = useState("");
  const getData = (page?: number) => {
    getUser({
      page: page || filter.page,
      query: searchQuery,
      sort_field: filter.sortField,
      is_asc: filter.isAsc,
    });
  };
  const getDataDebounce = debounce((e) => {
    setSearchQuery(e?.target?.value);
  }, 500);

  // Perform get data
  useEffect(() => {
    getData();
    setComponentDidMount(true);
  }, []);

  useEffect(() => {
    if (componentDidMount) {
      getData();
    }
  }, [filter]);

  useEffect(() => {
    if (componentDidMount) {
      getData(1);
    }
  }, [searchQuery]);

  const [editingItem, setEditingItem] = useState<TypeUser>(null);
  const [editingPasswordItem, setEditingPasswordItem] =
    useState<TypeUser>(null);

  const userColumns: TypeTableColumn<TypeUser>[] = [
    {
      title: "No",
      dataIndex: "no",
      align: "center",
      width: SIZE_COLUMN_XS,
    },
    {
      title: "Nama",
      dataIndex: "nama",
      sorter: true,
      editable: true,
      className: "min-w-32",
    },
    {
      title: "Username",
      dataIndex: "username",
      sorter: true,
      editable: true,
      className: "min-w-32",
    },
    {
      title: "Role",
      dataIndex: "role",
      sorter: true,
      editable: true,
      columnOptions: ROLE_OPTIONS,
      className: "min-w-32",
    },
    {
      title: "Aksi",
      align: "center",
      render: (text: string, item: TypeUser) => {
        return (
          <div className="flex gap-3 items-center justify-center">
            {item.id === user?.id ? null : (
              <Tooltip title="Edit">
                <Button
                  type="primary"
                  className="flex items-center justify-center p-1"
                  onClick={() => setEditingItem(item)}
                >
                  <BiEdit size={20} />
                </Button>
              </Tooltip>
            )}
            <Tooltip title="Reset Password">
              <Button
                type="primary"
                className="flex items-center justify-center p-1"
                onClick={() => setEditingPasswordItem(item)}
              >
                <MdLockReset size={20} />
              </Button>
            </Tooltip>
            {item.id === user?.id ? null : (
              <Tooltip title="Hapus">
                <Button
                  type="primary"
                  className="flex items-center justify-center p-1"
                  onClick={(e) => {
                    e.preventDefault();
                    confirm({
                      title: `Apakah Anda yakin ingin menghapus admin : ${item.nama}?`,
                      icon: null,
                      content:
                        "Data yang sudah dihapus tidak dapat dikembalikan lagi.",
                      okButtonProps: { type: "primary", danger: true },
                      okText: "Hapus",
                      cancelButtonProps: { type: "primary" },
                      cancelText: "Batal",
                      onOk() {
                        deleteData(item.id);
                      },
                      maskClosable: true,
                    });
                  }}
                  danger
                >
                  <BiTrash size={20} />
                </Button>
              </Tooltip>
            )}
          </div>
        );
      },
      width: SIZE_COLUMN_LG,
    },
  ];

  const addData = async (data: TypeUser) => {
    const res = await addUser(data);
    if (res?.data) {
      getData();
    }
    return res;
  };
  const editData = async (data: Partial<TypeUser>) => {
    return await editUser({ ...data, id: editingItem.id });
  };
  const deleteData = async (id: number) => {
    const messageKey = new Date().toISOString();
    showLoadingMessage(messageKey);
    const res = await deleteUser(id);
    if (res?.success) {
      showSuccessMessage(messageKey, "Data berhasil dihapus");
    } else {
      showErrorMessage(
        messageKey,
        res.displayMessage || FAILED_DISPLAY_MESSAGE
      );
    }
  };
  const changePassword = async (data: { id: number; password: string }) => {
    return await editUser({ id: data.id, password: data.password });
  };

  const [modalOpen, setModalOpen] = useState(false);
  return (
    <div className="w-full flex flex-col p-6 gap-6 items-center">
      <div className="w-full rounded-lg bg-white overflow-hidden flex p-4 flex-col gap-4 shadow-md transition-all">
        <h3>Data Admin</h3>
        <div className="w-full flex justify-between items-center gap-4">
          <div className="flex gap-4 items-center">
            <Search
              placeholder="Cari admin"
              onChange={getDataDebounce}
              className="w-auto md:w-96"
            />
          </div>
          <Button
            type="primary"
            className="flex items-center gap-2"
            onClick={() => setModalOpen(true)}
          >
            <RiAdminFill size={20} />
            Tambah Admin
          </Button>
        </div>
        <CustomTable<TypeUser>
          pagination={pagination}
          dataList={userList}
          columns={userColumns}
          isLoading={isLoadingGet}
          setFilter={setFilter}
          filter={filter}
          editingItem={editingItem}
          setEditingItem={setEditingItem}
          editData={editData}
        />
      </div>
      <AddDataModal<TypeUser>
        title="Tambah Admin"
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        columns={[
          ...userColumns.filter((item) => item.editable),
          {
            title: "Password",
            dataIndex: "password",
            editable: true,
          },
        ]}
        addData={addData}
      />
      <ResetPasswordModal
        item={editingPasswordItem}
        setItem={setEditingPasswordItem}
        changePassword={changePassword}
      />
    </div>
  );
};

export default User;
