import {
  PropsWithChildren,
  createContext,
  useState,
  useRef,
  useEffect,
} from "react";
import { TypeRekamMedis, TypeRekamMedisContext } from "../utils/types";
import axios from "axios";
import { FAILED_DATA } from "../utils/constants.tsx";
import { host } from "./config";
import Nota from "../components/Nota";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import { isMobile, jsonToFormData } from "../utils/functions";

axios.defaults.withCredentials = true;
const initialState: TypeRekamMedisContext = {
  rekamMedisList: [],
  allRekamMedisList: [],
  pagination: {
    current: 1,
    pageSize: 10,
    total: 0,
  },
  isLoadingGet: false,
};
export const RekamMedisContext =
  createContext<TypeRekamMedisContext>(initialState);
const RekamMedisState: React.FC<PropsWithChildren> = ({ children }) => {
  const [state, setState] = useState<TypeRekamMedisContext>(initialState);
  const dispatch = (payload: Partial<TypeRekamMedisContext>) => {
    setState((prevState) => ({
      ...prevState,
      ...payload,
    }));
  };

  const getRekamMedis = async (data: {
    page?: number;
    query?: string;
    sort_field?: string;
    is_asc?: boolean;
  }) => {
    dispatch({ isLoadingGet: true });
    try {
      const res = await axios.get(host + "/rekam_medis/get_rekam_medis.php", {
        params: data,
      });
      if (res.data.success) {
        dispatch({
          rekamMedisList: res.data.data || [],
          pagination: res.data.pagination,
        });
      }
      return res.data;
    } catch (err) {
      return FAILED_DATA;
    } finally {
      dispatch({ isLoadingGet: false });
    }
  };

  const addRekamMedis = async (
    data: TypeRekamMedis & {
      dokumen_pendukung: File[];
      dokumen_pendukung_title: string[];
    }
  ) => {
    try {
      const formData = jsonToFormData(data, [
        "perawatan",
        "dokumen_pendukung",
        "dokumen_pendukung_title",
      ]);

      const res = await axios.post(
        host + "/rekam_medis/add_rekam_medis.php",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      return res.data;
    } catch (err) {
      return FAILED_DATA;
    }
  };

  const editRekamMedis = async (
    data: TypeRekamMedis & {
      dokumen_pendukung: File[];
      dokumen_pendukung_title: string[];
      dokumen_pendukung_keep: number[];
    }
  ) => {
    try {
      const formData = jsonToFormData(data, [
        "perawatan",
        "dokumen_pendukung",
        "dokumen_pendukung_title",
        "dokumen_pendukung_keep",
      ]);

      const res = await axios.post(
        host + "/rekam_medis/edit_rekam_medis.php",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      const prevIndex = state.rekamMedisList.findIndex(
        (item) => item.id === data.id
      );
      if (prevIndex !== -1) {
        const newRekamMedisList = [...state.rekamMedisList];
        newRekamMedisList[prevIndex] = {
          ...res.data.data,
        };
        dispatch({ rekamMedisList: newRekamMedisList });
      }

      return res.data;
    } catch (err) {
      return FAILED_DATA;
    }
  };

  const deleteRekamMedis = async (id: number) => {
    try {
      const res = await axios.post(
        host + "/rekam_medis/delete_rekam_medis.php",
        { id }
      );

      const prevIndex = state.rekamMedisList.findIndex(
        (item) => item.id === id
      );
      if (prevIndex !== -1) {
        const newRekamMedisList = [...state.rekamMedisList];
        newRekamMedisList.splice(prevIndex, 1);
        dispatch({ rekamMedisList: newRekamMedisList });
      }

      return res.data;
    } catch (err) {
      return FAILED_DATA;
    }
  };

  const getRekamMedisByDate = async (data: {
    id_ruang?: number;
    start_date: string;
    end_date: string;
  }) => {
    dispatch({ isLoadingGet: true });
    try {
      const res = await axios.get(
        host + "/rekam_medis/get_rekam_medis_by_date.php",
        {
          params: data,
        }
      );
      if (res.data.success) {
        dispatch({
          allRekamMedisList: res.data.data || [],
        });
      }
      return res.data;
    } catch (err) {
      return FAILED_DATA;
    } finally {
      dispatch({ isLoadingGet: false });
    }
  };

  const getAllRekamMedisByNoRM = async (data: { no_rm: string }) => {
    try {
      const res = await axios.get(
        host + "/rekam_medis/get_rekam_medis_by_no_rm.php",
        {
          params: data,
        }
      );

      return res.data;
    } catch (err) {
      return FAILED_DATA;
    } finally {
    }
  };

  const printRekamMedis = (rekamMedis: TypeRekamMedis) => {
    setDataToPrint(rekamMedis);
  };
  const [dataToPrint, setDataToPrint] = useState<TypeRekamMedis>(null);
  const componentToPrint = useRef<HTMLDivElement>(null);

  const handlePrint = async () => {
    const content = componentToPrint.current;
    const iframe = document.createElement("iframe");

    // Set the iframe style to match the hidden component
    iframe.style.position = "absolute";
    iframe.style.left = "-9999px";

    // Append the iframe to the body
    document.body.appendChild(iframe);

    // Clone the component's content into the iframe
    const contentClone = content.cloneNode(true);
    const iframeDocument =
      iframe.contentDocument || iframe.contentWindow.document;

    iframeDocument.body.appendChild(contentClone);

    const link = document.createElement("link");
    link.href = `${process.env.PUBLIC_URL}/assets/print-styles.css`;
    link.rel = "stylesheet";
    iframeDocument.head.appendChild(link);

    const styleElement = document.createElement("style");
    styleElement.textContent = `
        @page {
          size: 400px ${
            componentToPrint.current?.scrollHeight
              ? componentToPrint.current?.scrollHeight + 64
              : null
          }px;
          margin: 0;
          /* You can specify other @page styles here */
        }
      `;
    iframeDocument.head.appendChild(styleElement);

    function printDirectly() {
      setTimeout(() => {
        iframe.contentWindow.print();
      }, 300);
    }

    //@ts-ignore
    if (isMobile()) {
      try {
        // Convert iframe to image
        html2canvas(iframeDocument.documentElement).then(async function (
          canvas
        ) {
          const img = canvas.toDataURL("image/png");
          const width = canvas.width;
          const height = canvas.height;
          const pdf = new jsPDF({
            orientation: height > width ? "p" : "l",
            unit: "px",
            format: [width, height],
          });
          pdf.addImage(img, "PNG", 0, 0, width, height);
          const pdfBlob = pdf.output("blob");
          const data = {
            files: [
              new File([pdfBlob], "nota.pdf", {
                type: pdfBlob.type,
              }),
            ],
          };
          navigator.share(data);
        });
      } catch (err) {
        printDirectly();
      }
    } else {
      printDirectly();
    }
    setTimeout(() => {
      document.body.removeChild(iframe);
    }, 5000);
  };

  useEffect(() => {
    if (dataToPrint) {
      handlePrint();
    }
  }, [dataToPrint]);
  return (
    <RekamMedisContext.Provider
      value={{
        ...state,
        getRekamMedis,
        addRekamMedis,
        editRekamMedis,
        deleteRekamMedis,
        getRekamMedisByDate,
        printRekamMedis,
        getAllRekamMedisByNoRM,
      }}
    >
      <Nota rekamMedis={dataToPrint} ref={componentToPrint} />
      {children}
    </RekamMedisContext.Provider>
  );
};

export default RekamMedisState;
