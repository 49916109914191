import { useContext, useEffect, useState } from "react";
import { Button, Descriptions, Form, Modal } from "antd";
import { ContentComponent, EditorComponent } from "./CustomTable";
import { TypeTableColumn } from "../utils/types";
import { GlobalContext } from "../contexts/GlobalState";
import { generateArray } from "../utils/functions";
import { BiEdit, BiSave } from "react-icons/bi";
import { FAILED_DISPLAY_MESSAGE } from "../utils/constants";

const { useForm } = Form;
interface TypePreviewModal<A> {
  previewItem: any;
  columns: TypeTableColumn<A>[];
  title: string;
  setPreviewItem: React.Dispatch<React.SetStateAction<A>>;
  editData?: (values: any) => Promise<any>;
}

const PreviewModal = <A,>({
  previewItem,
  columns,
  title,
  setPreviewItem,
  editData,
}: TypePreviewModal<A>) => {
  const { user, showLoadingMessage, showErrorMessage, showSuccessMessage } =
    useContext(GlobalContext);
  const [isEditing, setIsEditing] = useState(false);
  const [editForm] = useForm();
  const [quantityState, setQuantityState] = useState<
    {
      dataIndex: string;
      quantity: number;
    }[]
  >([]);

  useEffect(() => {
    setQuantityState(
      columns
        .filter((item) => item.withQuantity)
        .map((item) => {
          let initialLength = 0;
          const text = previewItem?.[item.dataIndex as string];
          if (item.withQuantity && item.joinInitial) {
            initialLength = (text || []).length;
          }
          return {
            dataIndex: item.dataIndex as string,
            quantity: initialLength,
          };
        })
    );
    if (!isEditing) {
      editForm.resetFields();
    }
  }, [isEditing, previewItem]);

  const handleAddField = (dataIndex: string, count: number = 1) => {
    setQuantityState((prevState) => {
      const newState = [...prevState];
      const index = newState.findIndex((item) => item.dataIndex === dataIndex);
      newState[index].quantity += count;
      return newState;
    });
  };

  const handleRemoveField = (
    dataIndex: string,
    itemIndex: number,
    suffixList: string[]
  ) => {
    setQuantityState((prevState) => {
      const newState = [...prevState];
      const index = newState.findIndex((item) => item.dataIndex === dataIndex);
      const prevQuantity = prevState[index].quantity;
      const newQuantity = prevState[index].quantity - 1;
      newState[index].quantity = newQuantity;

      const prevDataFields = [];
      generateArray(prevQuantity).forEach((item2, index2) => {
        suffixList.forEach((suffix) => {
          prevDataFields.push(`${dataIndex}${suffix}[${index2}]`);
        });
      });

      const prevData = editForm.getFieldsValue();
      const newData = {};

      suffixList.forEach((suffix) => {
        newData[`${dataIndex}${suffix}`] = [];
      });

      generateArray(newQuantity).forEach((item2, index2) => {
        if (index2 < itemIndex) {
          suffixList.forEach((suffix) => {
            newData[`${dataIndex}${suffix}`].push(
              prevData[`${dataIndex}${suffix}`][index2]
            );
          });
        } else {
          suffixList.forEach((suffix) => {
            newData[`${dataIndex}${suffix}`].push(
              prevData[`${dataIndex}${suffix}`][index2 + 1]
            );
          });
        }
      });

      editForm.resetFields(prevDataFields);

      const newFieldsValue = {};
      suffixList.forEach((suffix) => {
        newFieldsValue[`${dataIndex}${suffix}`] =
          newData[`${dataIndex}${suffix}`];
      });
      editForm.setFieldsValue(newFieldsValue);

      return newState;
    });
  };

  return (
    <Modal
      title={title}
      open={previewItem}
      cancelButtonProps={{ style: { display: "none" } }}
      okText="Tutup"
      okButtonProps={{ className: isEditing ? "hidden" : "" }}
      onOk={() => setPreviewItem(null)}
      onCancel={() => {
        if (!isEditing) {
          setPreviewItem(null);
        }
      }}
      className="max-w-none !w-11/12 md:!w-2/3 !p-4 flex flex-col gap-3"
      centered
    >
      <div className="w-full flex p-4 flex-col gap-3">
        {user?.role === "SUPERADMIN" ? (
          <div className="flex w-full justify-end items-center gap-3">
            {isEditing ? (
              <>
                <Button
                  className="flex items-center gap-2"
                  onClick={() => {
                    setIsEditing(false);
                  }}
                >
                  Batalkan
                </Button>
                <Button
                  type="primary"
                  className="flex items-center gap-2"
                  onClick={() => editForm.submit()}
                >
                  <BiSave size={20} />
                  Simpan Perubahan
                </Button>
              </>
            ) : (
              <Button
                type="primary"
                className="flex items-center gap-2"
                onClick={() => setIsEditing(true)}
              >
                <BiEdit size={20} />
                Edit Rekam Medis
              </Button>
            )}
          </div>
        ) : null}
        <Form
          form={editForm}
          onFinish={async (values) => {
            const messageKey = new Date().toISOString();
            showLoadingMessage(messageKey);
            const res = await editData(values);
            if (res.success) {
              showSuccessMessage(messageKey, "Data berhasil diubah");
              setIsEditing(false);
              setPreviewItem(res.data);
            } else {
              showErrorMessage(
                messageKey,
                res.displayMessage || FAILED_DISPLAY_MESSAGE
              );
            }
          }}
        >
          <Descriptions className="w-full" column={3} bordered>
            {columns.map((item, index) => {
              const text = previewItem?.[item.dataIndex as string];
              return (
                <Descriptions.Item
                  key={index}
                  label={item.title as string}
                  labelStyle={{ width: "30%" }}
                  span={3}
                >
                  {isEditing && item.editable ? (
                    <EditorComponent
                      text={text}
                      column={item}
                      dataForm={editForm}
                      quantityState={quantityState}
                      handleAddField={handleAddField}
                      handleRemoveField={handleRemoveField}
                    />
                  ) : (
                    <ContentComponent
                      text={text || item.value}
                      record={previewItem}
                      column={item}
                    />
                    // <p className="whitespace-pre-wrap">{item.value}</p>
                  )}
                </Descriptions.Item>
              );
            })}
          </Descriptions>
        </Form>
      </div>
    </Modal>
  );
};

export default PreviewModal;
