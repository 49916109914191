import { forwardRef } from "react";
import dayjs from "dayjs";
import { TypeRekamMedis } from "../utils/types";
import { formatIDRPrice, totalPerawatanPrice } from "../utils/functions";
import { DATE_FORMAT } from "../utils/constants.tsx";

const Nota = forwardRef<HTMLDivElement, { rekamMedis: TypeRekamMedis }>(
  (props, ref) => {
    const { rekamMedis } = props;
    if (rekamMedis) {
      const currentDate = dayjs(rekamMedis.tgl_periksa).format(DATE_FORMAT);
      const totalPrice = formatIDRPrice(
        totalPerawatanPrice(rekamMedis.perawatan)
      );
      return (
        <div
          ref={ref}
          id="nota"
          className="w-100 absolute h-min p-8 box-border"
          style={{ zIndex: "-100" }}
        >
          <div className="w-full h-full">
            <div className="w-full">
              <div className="w-full text-center">
                <h2>{process.env.REACT_APP_APP_NAME}</h2>
                <h4>Nota Pembayaran</h4>
                <h4>
                  #{rekamMedis.no_rm}
                  {rekamMedis.id}
                </h4>
              </div>
              <hr className="mt-3" />
              <div className="text-left mt-8">
                <p>No RM: {rekamMedis.no_rm}</p>
                <p>Tgl Periksa: {currentDate}</p>
                <p>Nama Pasien: {rekamMedis.nama_pasien}</p>
              </div>
              <table className="w-full mt-8">
                <thead>
                  <tr>
                    <th
                      className="text-left p-1"
                      style={{ borderTop: "1px dashed black" }}
                    >
                      Perawatan
                    </th>
                    <th
                      className="text-center p-1"
                      style={{ borderTop: "1px dashed black" }}
                    >
                      Jumlah
                    </th>
                    <th
                      className="text-right p-1"
                      style={{
                        borderTop: "1px dashed black",
                        borderRight: "1px dashed black",
                      }}
                    >
                      Total
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {rekamMedis.perawatan.map((item, index) => {
                    const formattedPrice = formatIDRPrice(
                      item.jumlah * item.harga
                    );
                    return (
                      <tr key={index}>
                        <td className="text-left p-1">{item.nama_perawatan}</td>
                        <td className="text-center p-1">{item.jumlah}</td>
                        <td
                          className="text-right p-1"
                          style={{ borderRight: "1px dashed black" }}
                        >
                          {formattedPrice}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
              <div className="total-amount text-right mt-3 px-2">
                <p>
                  <strong>Total:</strong> {totalPrice}
                </p>
              </div>
            </div>
          </div>
        </div>
      );
    } else return null;
  }
);

export default Nota;
