import { PropsWithChildren, createContext, useState } from "react";
import { TypeAntrean, TypeAntreanContext } from "../utils/types";
import axios from "axios";
import { FAILED_DATA } from "../utils/constants.tsx";
import { host } from "./config";

axios.defaults.withCredentials = true;
const initialState: TypeAntreanContext = {
  allAntreanList: [],
  pagination: {
    current: 1,
    pageSize: 10,
    total: 0,
  },
  isLoadingGet: false,
};
export const AntreanContext = createContext<TypeAntreanContext>(initialState);
const AntreanState: React.FC<PropsWithChildren> = ({ children }) => {
  const [state, setState] = useState<TypeAntreanContext>(initialState);
  const dispatch = (payload: Partial<TypeAntreanContext>) => {
    setState((prevState) => ({
      ...prevState,
      ...payload,
    }));
  };

  const getAntreanByDate = async (data: {
    id_ruang: number;
    start_date: string;
    end_date: string;
  }) => {
    dispatch({ isLoadingGet: true });
    try {
      const res = await axios.get(host + "/antrean/get_antrean_by_date.php", {
        params: data,
      });
      if (res.data.success) {
        dispatch({
          allAntreanList: res.data.data || [],
        });
      }
      return res.data;
    } catch (err) {
      return FAILED_DATA;
    } finally {
      dispatch({ isLoadingGet: false });
    }
  };

  const addAntrean = async (
    data: TypeAntrean & { start_date: string; end_date: string }
  ) => {
    try {
      const res = await axios.post(host + "/antrean/add_antrean.php", data);
      if (res.data.success) {
        dispatch({
          allAntreanList: [...state.allAntreanList, res.data.data],
        });
      }
      return res.data;
    } catch (err) {
      return FAILED_DATA;
    }
  };

  const editAntrean = async (data: Partial<TypeAntrean>) => {
    try {
      const res = await axios.post(host + "/antrean/edit_antrean.php", data);

      const prevIndex = state.allAntreanList.findIndex(
        (item) => item.id === data.id
      );
      if (prevIndex !== -1) {
        const newAntreanList = [...state.allAntreanList];
        newAntreanList[prevIndex] = {
          ...newAntreanList[prevIndex],
          ...data,
        };
        dispatch({ allAntreanList: newAntreanList });
      }

      return res.data;
    } catch (err) {
      return FAILED_DATA;
    }
  };

  const deleteAntrean = async (id: number) => {
    try {
      const res = await axios.post(host + "/antrean/delete_antrean.php", {
        id,
      });

      const prevIndex = state.allAntreanList.findIndex(
        (item) => item.id === id
      );
      if (prevIndex !== -1) {
        const newAntreanList = [...state.allAntreanList];
        newAntreanList.splice(prevIndex, 1);
        dispatch({ allAntreanList: newAntreanList });
      }

      return res.data;
    } catch (err) {
      return FAILED_DATA;
    }
  };

  return (
    <AntreanContext.Provider
      value={{
        ...state,
        addAntrean,
        editAntrean,
        deleteAntrean,
        getAntreanByDate,
      }}
    >
      {children}
    </AntreanContext.Provider>
  );
};

export default AntreanState;
